import React from 'react';
import {
  Box,
  Chip,
  Typography,
  Button,
  Paper,
  Grid,
  ButtonGroup,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Tabs,
  Tab,
  Badge,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

import {
  FIND_RENTAL_BY_UID,
  APPROVE_CONTRACT,
  GENERATE_MOU,
  GENERATE_INSTALLATION,
  UPDATE_RENTAL,
  CREATE_TRAVEL_DOC,
  CANCEL_RENTAL,
} from '../../graphql/Rental';
import { CREATE_OFFERING } from '../../graphql/Offering';
import GlobalContext from '../../utils/GlobalContext';
import OfferingPDF from '../PDF/OfferingPDF';
import MouPDF from '../PDF/MouPDF';
import InstallationPDF from '../PDF/InstallationPDF';
import { checkStatusRental, chipColor } from '../../utils';
import InvoicePage from './InvoicePage';
import ClaimPage from './ClaimPage';
import RentalEdit from './RentalEdit';
import ModalGenerateMoU from './ModalGenerateMoU';
import TravelRental from '../PDF/TravelRental';
import dayjs from 'dayjs';
const TravelRentalForm = React.lazy(() => import('./TravelRentalForm'));

const RentalDetail = (props) => {
  const { cid, rid } = props?.match?.params;
  const { setGlobalLoading, setSnack } = React.useContext(GlobalContext);
  const [rental, setRental] = React.useState(null);
  const anchorRef = React.useRef(null);
  const [openButton, setOpenButton] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const options = checkStatusRental(rental);
  const [open, setOpen] = React.useState(false);
  const [openMou, setOpenMou] = React.useState(false);
  const [openTravel, setOpenTravel] = React.useState(false);
  const [traveDocsData, setTraveDocsData] = React.useState({
    driver_name: '',
    driver_phone: '',
    shipped_with: '',
    licence_plat: '',
  });
  const [date, setDate] = React.useState(new Date());
  const [pendingInv, setPendingInv] = React.useState(false);

  /* FETCH DATA COMPANIES */
  const {
    loading: loadRental,
    data: dataRental,
    refetch,
  } = useQuery(FIND_RENTAL_BY_UID, {
    variables: { uid: rid },
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (loadRental) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataRental) {
      setRental(dataRental.findRentalByUid);
      const pending = dataRental?.findRentalByUid?.invoices?.filter(
        (x) => x?.is_deadline
      );
      setPendingInv(pending?.length ?? false);
      setGlobalLoading(false);
    }
  }, [dataRental]);

  const [printOfferingPDF] = useMutation(CREATE_OFFERING, {
    onCompleted: (d) => {
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data cetak tagihan berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);

      return d?.createOffering;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Data cetak tagihan berhasil ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [prinMouPDF] = useMutation(GENERATE_MOU, {
    onCompleted: (d) => {
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Berkas MoU berhasil dicetak',
        opened: true,
      });
      setGlobalLoading(false);

      return d?.createOffering;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Data cetak tagihan berhasil ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [prinInstallationPDF] = useMutation(GENERATE_INSTALLATION, {
    onCompleted: (d) => {
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Invoice Instalasi & Ongkir berhasil dicetak',
        opened: true,
      });
      setGlobalLoading(false);

      return d?.createOffering;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Data cetak tagihan berhasil ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [approveContract] = useMutation(APPROVE_CONTRACT, {
    onCompleted: (d) => {
      setRental({ ...d?.approveContract });
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Status berhasil diperbaruhi',
        opened: true,
      });
      setGlobalLoading(false);

      return d?.approveContract;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Data cetak tagihan berhasil ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [updateRental] = useMutation(UPDATE_RENTAL, {
    onCompleted: (d) => {
      setRental({ ...d?.updateRental });
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data kontrak berhasil diperbaruhi',
        opened: true,
      });
      setGlobalLoading(false);

      return d?.updateRental;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Data cetak tagihan berhasil ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [insertTravelDocument] = useMutation(CREATE_TRAVEL_DOC, {
    onCompleted: (d) => {
      const data = { ...d?.createTravelRental };
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Surat Jalan berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      setOpenTravel(false);

      TravelRental(data);
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = 'Data surat jalan gagal disimpan';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [cancelRental] = useMutation(CANCEL_RENTAL, {
    onCompleted: (d) => {
      setRental({ ...d?.cancelRental });
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Status berhasil diperbaruhi',
        opened: true,
      });
      setGlobalLoading(false);

      return d?.cancelRental;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Data cetak tagihan berhasil ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const handleClick = async () => {
    if (options[selectedIndex] === 'Cetak Surat Penawaran') {
      const result = await printOfferingPDF({ variables: { rental_uid: rid } });
      OfferingPDF(result?.data?.createOffering);
      return true;
    }

    if (options[selectedIndex] === 'Cetak MoU') {
      if (rental?.generate_mou_date) {
        const result = await prinMouPDF({
          variables: {
            rental_uid: rid,
            date: dayjs(rental?.generate_mou_date).format('YYYY-MM-DD'),
          },
        });
        MouPDF(result?.data?.generateMou);
        setOpenMou(false);
        return true;
      } else {
        setOpenMou(true);
      }
    }

    if (options[selectedIndex] === 'Approve Kontrak') {
      return approveContract({ variables: { uid: rid } });
    }

    if (options[selectedIndex] === 'Cetak Invoice Instalasi & Ongkir') {
      const result = await prinInstallationPDF({
        variables: { rental_uid: rid },
      });
      InstallationPDF(
        result?.data?.generateInvoiceInstallation,
        result?.data?.generateInvoiceInstallation?.invoice_installation
      );
      return true;
    }

    if (options[selectedIndex] === 'Edit Kontrak') {
      setOpen(true);
    }

    if (options[selectedIndex] === 'Cetak Surat Jalan') {
      setOpenTravel(true);
    }

    if (options[selectedIndex] === 'Batalkan Penawaran') {
      return cancelRental({ variables: { uid: rid } });
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpenButton(false);
  };

  const handleToggle = () => {
    setOpenButton((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenButton(false);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async (values) => {
    const data = {
      company_id: rental?.company?.id,
      photocopy_id: rental?.photocopy?.id,
      employee_id: rental?.employee?.id,
      contract_date: values?.contractDate,
      initial_counter: rental?.initial_counter,
      contract_value: Number(values?.contractValue),
      free_printable: Number(values?.freePrintable),
      price_per_printable: Number(values?.pricePerPrintable),
      tax: values?.tax,
      installation_fee: values?.installationFee,
      contract_periode: Number(values?.contractPeriode),
    };

    await updateRental({ variables: { uid: rental?.uid, data } });
    setOpen(false);
  };

  const handleSubmitTravel = () => {
    const data = {
      rental_id: rental?.id,
      reference_number: '1',
      ...traveDocsData,
    };

    insertTravelDocument({
      variables: { data },
    });
  };

  const handleGenerateMou = async (values) => {
    const result = await prinMouPDF({
      variables: { rental_uid: rid, date: values?.generateMouDate },
    });
    MouPDF(result?.data?.generateMou);
    setOpenMou(false);
    return true;
  };

  return (
    <React.Fragment>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            bgcolor: 'background.paper',
            borderRadius: 1,
            alignItems: 'center',
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h6' mb={2} gutterBottom>
              Data Customer Rental &nbsp;
              <Chip
                label={rental?.status}
                variant='filled'
                color={chipColor(rental?.status)}
                size='small'
              />
            </Typography>
          </Box>
          <Box
            sx={{
              p: 0.5,
              m: 0.5,
            }}
          >
            <Button
              component={Link}
              to={`/admin/rental/${cid}`}
              variant='text'
              size='small'
            >
              Kembali
            </Button>{' '}
            &nbsp;
            {!['DONE', 'CANCEL'].includes(rental?.status) && (
              <>
                <ButtonGroup
                  variant='outlined'
                  ref={anchorRef}
                  aria-label='split button'
                >
                  <Button
                    size='small'
                    onClick={handleClick}
                    // disabled={order?.status === 'NEW'}
                  >
                    {options[selectedIndex]}
                  </Button>
                  <Button
                    size='small'
                    aria-controls={openButton ? 'split-button-menu' : undefined}
                    aria-expanded={openButton ? 'true' : undefined}
                    aria-label='select merge strategy'
                    aria-haspopup='menu'
                    onClick={handleToggle}
                    // disabled={order?.status === 'NEW'}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={openButton}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: 1000000 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id='split-button-menu' autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
          </Box>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label='Informasi Dasar' {...a11yProps(0)} />
            <Tab
              // disabled={rental?.status !== 'ONGOING'}
              label={
                <>
                  <Badge color='error' invisible={!pendingInv} variant='dot'>
                    Invoice &nbsp;
                  </Badge>
                </>
              }
              {...a11yProps(1)}
            />
            <Tab
              // disabled={rental?.status !== 'ONGOING'}
              label='Klaim'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2} sx={{ pb: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper
                sx={{
                  padding: 2,
                  margin: 'auto',
                  maxWidth: '100%',
                }}
              >
                {/* DATA PERUSAHAAN */}
                <Grid item xs={12} sm container sx={{ pb: 3 }}>
                  <Grid
                    item
                    xs
                    container
                    direction='column'
                    spacing={2}
                    sx={{ pb: 2 }}
                  >
                    <Grid item xs>
                      <Typography gutterBottom variant='subtitle2'>
                        Data Perusahaan
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={12} container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Nama Perusahaan
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.company?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Alamat
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.company?.address}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        PIC
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.company?.pic}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        No. Telpon
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.company?.phone}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* DATA MESIN FOTOKOPI */}
                <Grid item xs={12} sm container>
                  <Grid
                    item
                    xs
                    container
                    direction='column'
                    spacing={2}
                    sx={{ pb: 2 }}
                  >
                    <Grid item xs>
                      <Typography gutterBottom variant='subtitle2'>
                        Data Mesin Fotokopi
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={12} container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Nama Mesin
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.photocopy?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        No. Seri
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.photocopy?.serial_number}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Tipe
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.photocopy?.type}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Counter Awal
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.initial_counter}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Counter Akhir
                      </Typography>
                      <Typography variant='body2'>CEK</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper
                sx={{
                  padding: 2,
                  margin: 'auto',
                  maxWidth: '100%',
                }}
              >
                <Grid item xs={12} sm container>
                  <Grid
                    item
                    xs
                    container
                    direction='column'
                    spacing={2}
                    sx={{ pb: 2 }}
                  >
                    <Grid item xs>
                      <Typography gutterBottom variant='subtitle2'>
                        Data Kontrak
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={12} container spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Tanggal Kontrak
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.contract_date}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Masa Kontrak
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.contract_periode}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Tanggal Kontrak Berakhir
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.end_of_contract}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Nilai Kontrak
                      </Typography>
                      <Typography variant='body2'>
                        {`Rp. ${rental?.contract_value.toLocaleString(
                          'id-ID'
                        )}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Free Cetak
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.free_printable.toLocaleString('id-ID')}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Lebih Cetak
                      </Typography>
                      <Typography variant='body2'>
                        {`Rp. ${rental?.price_per_printable.toLocaleString(
                          'id-ID'
                        )}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        PPN 12%
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.tax ? 'Ya' : ' Tidak'}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Biaya Instalasi
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.installation_fee ? 'Ya' : ' Tidak'}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Nama Teknisi
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.technician_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='subtitle2' sx={{ fontWeight: 500 }}>
                        Nama Marketing
                      </Typography>
                      <Typography variant='body2'>
                        {rental?.employee?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InvoicePage
            rental={rental}
            setRental={setRental}
            refetch={refetch}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ClaimPage rental={rental} />
        </TabPanel>
      </Box>
      <React.Suspense fallback={<div></div>}>
        <RentalEdit
          open={open}
          setOpen={setOpen}
          handleSubmit={handleSubmit}
          data={rental}
          setData={setRental}
        />
      </React.Suspense>
      <React.Suspense fallback={<div></div>}>
        <ModalGenerateMoU
          open={openMou}
          setOpen={setOpenMou}
          handleGenerateMou={handleGenerateMou}
          date={date}
          setDate={setDate}
        />
      </React.Suspense>
      <React.Suspense fallback={<div></div>}>
        <TravelRentalForm
          open={openTravel}
          setOpen={setOpenTravel}
          traveDocsData={traveDocsData}
          setTraveDocsData={setTraveDocsData}
          handleSubmit={handleSubmitTravel}
        />
      </React.Suspense>
    </React.Fragment>
  );
};

export default RentalDetail;
