import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, IconButton, Tooltip, Badge, Chip } from '@mui/material';
import { useMutation } from '@apollo/client';
import PrintIcon from '@mui/icons-material/Print';
import CheckIcon from '@mui/icons-material/Check';
import {
  UPDATE_INVOICE,
  UPDATE_PAYMENT_INVOICE,
  UPDATE_INITIAL_COUNTER,
  UPDATE_INVOICE_DATE,
} from '../../graphql/Rental';
import GlobalContext from '../../utils/GlobalContext';
import InvoiceFCPDF from '../PDF/InvoiceFCPDF';
import { chipPayment } from '../../utils';

const InvoicePage = (props) => {
  const { rental, setRental, refetch } = props;
  const { setGlobalLoading, setSnack } = React.useContext(GlobalContext);

  const [updateInvoiceDate] = useMutation(UPDATE_INVOICE_DATE, {
    onCompleted: (d) => {
      refetch();
      return true;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Counter awal berhasil diubah ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const handleUpdateDate = async (id) => {
    return updateInvoiceDate({ variables: { id } });
  };

  const columns = [
    {
      field: 'contract_to',
      headerName: 'Ke-',
      width: 10,
    },
    {
      field: 'invoice_number',
      headerName: 'No. Invoice',
      width: 200,
    },
    {
      field: 'due_date',
      headerName: 'Jatuh Tempo',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.due_date}
            &emsp;
            <Badge
              color='error'
              invisible={!params?.row?.is_deadline}
              variant='dot'
            />
          </>
        );
      },
    },
    {
      field: 'initial_counter',
      headerName: 'Counter Awal',
      flex: 1,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'final_counter',
      headerName: 'Counter Akhir',
      flex: 1,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      editable: (params) => {
        console.log('asdasd', params);
      },
    },
    {
      field: 'total',
      headerName: 'Total Tagihan',
      flex: 1,
      renderCell: (params) => {
        if (params?.row?.total)
          return `Rp. ${params?.row?.total?.toLocaleString('id-ID')}`;
        return null;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <Chip
              label={params?.row?.status}
              variant='filled'
              color={chipPayment(params?.row?.status)}
              size='small'
            />
          </React.Fragment>
        );
      },
    },
    {
      field: 'oid',
      headerName: 'Aksi',
      width: 90,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title='Cetak Invoice' placement='top'>
              <IconButton
                aria-label='detail'
                size='small'
                disabled={!params?.row?.invoice_number}
                onClick={() => {
                  if (!params?.row?.generate_at) {
                    handleUpdateDate(params?.row?.id);
                    InvoiceFCPDF(rental, params?.row, true);
                  } else {
                    InvoiceFCPDF(rental, params?.row);
                  }
                }}
              >
                <PrintIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            {params?.row?.status === 'WAITING PAYMENT' && (
              <Tooltip title='Sudah Bayar' placement='top'>
                <IconButton
                  aria-label='detail'
                  size='small'
                  onClick={() => handleUpdatePaymentStatus(params?.row?.id)}
                >
                  <CheckIcon fontSize='inherit' />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const [updateInvoice] = useMutation(UPDATE_INVOICE, {
    onCompleted: (d) => {
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Status berhasil diperbaruhi',
        opened: true,
      });
      setGlobalLoading(false);

      return d?.approveContract;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Data cetak tagihan berhasil ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [updatePaymentInvoice] = useMutation(UPDATE_PAYMENT_INVOICE, {
    onCompleted: (d) => {
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Status berhasil diperbaruhi',
        opened: true,
      });
      setGlobalLoading(false);
      refetch();
      return true;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Data cetak tagihan berhasil ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [updateInitialCounter] = useMutation(UPDATE_INITIAL_COUNTER, {
    onCompleted: (d) => {
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Counter awal berhasil diubah',
        opened: true,
      });
      setGlobalLoading(false);
      refetch();
      return true;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Counter awal berhasil diubah ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const processRowUpdate = async (newRow, oldRow) => {
    debugger;
    // if (newRow?.final_counter <= 0) return newRow;
    // if (newRow?.initial_counter <= 0) return newRow;
    // if (
    //   newRow?.initial_counter > newRow?.final_counter &&
    //   newRow?.final_counter !== 0
    // ) {
    //   setSnack({
    //     variant: 'error',
    //     message: 'Counter awal tidak boleh kurang dari counter akhir',
    //     opened: true,
    //   });
    //   return newRow;
    // }
    // if (
    //   newRow?.final_counter < newRow?.initial_counter &&
    //   newRow?.initial_counter !== 0
    // ) {
    //   setSnack({
    //     variant: 'error',
    //     message: 'Counter akhir tidak boleh kurang dari counter awal',
    //     opened: true,
    //   });
    //   return newRow;
    // }

    if (newRow?.final_counter !== oldRow?.final_counter) {
      const updatedRow = { ...newRow };
      const updated = await updateInvoice({
        variables: {
          id: newRow?.id,
          data: { final_counter: newRow?.final_counter },
        },
      });
      const contractTo = updated?.data?.updateInvoice?.contract_to;
      const invoices = rental?.invoices?.map((x) => {
        let obj = { ...x };

        if (x.id === updated.data.updateInvoice.id)
          obj = updated?.data?.updateInvoice;

        if (contractTo + 1 === x?.contract_to)
          obj.initial_counter = newRow?.final_counter;

        return obj;
      });

      setRental({ ...rental, invoices: [...invoices] });
      return updatedRow;
    }
    debugger;
    if (newRow?.initial_counter !== oldRow?.initial_counter) {
      await updateInitialCounter({
        variables: {
          id: newRow?.id,
          initial_counter: newRow?.initial_counter,
        },
      });
      debugger;
      return true;
    }

    return newRow;
  };

  const [selectionModel, setSelectionModel] = React.useState([]);

  const handleUpdatePaymentStatus = async (id) => {
    return updatePaymentInvoice({ variables: { id } });
  };
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          experimentalFeatures={{ newEditingApi: true }}
          rows={rental?.invoices ?? []}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          processRowUpdate={processRowUpdate}
          isCellEditable={(params) => {
            // https://codesandbox.io/s/iscelleditablegrid-material-demo-o3g8p?file=/demo.js

            if (
              params?.field === 'initial_counter' &&
              params?.row?.contract_to === 1 &&
              params?.row?.status === 'GENERATED'
            ) {
              return true;
            } else if (params?.field === 'final_counter') {
              if (
                ['GENERATED', 'WAITING PAYMENT'].includes(params?.row?.status)
              )
                return true;
              else return false;
            }

            return false;
          }}
          onSelectionModelChange={(selection) => {
            if (selection.length > 1) {
              const selectionSet = new Set(selectionModel);
              const result = selection.filter((s) => !selectionSet.has(s));

              setSelectionModel(result);
            } else {
              setSelectionModel(selection);
            }
          }}
        />
      </div>
    </Paper>
  );
};

export default InvoicePage;
