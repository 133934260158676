import jsPDF from 'jspdf';
import dayjs from 'dayjs';
import logo_gja from './logo-gja.png';
import sign from './sign.png';
import locale_id from 'dayjs/locale/id';
dayjs.locale('id');

function getDataUri(url) {
  return new Promise((resolve) => {
    var image = new Image();
    image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain

    image.onload = function () {
      var canvas = document.createElement('canvas');
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;

      //next three lines for white background in case png has a transparent background
      var ctx = canvas.getContext('2d');
      ctx.fillStyle = '#fff'; /// set white fill style
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      canvas.getContext('2d').drawImage(this, 0, 0);

      resolve(canvas.toDataURL('image/jpeg'));
    };

    image.src = url;
  });
}

const OfferingPDF = async (data) => {
  let doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    format: [210, 330],
  });
  const image = new Image();
  const marginLR = 15;
  image.src = logo_gja;
  const image2 = new Image();
  image2.src = sign;

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const COMPANY_NAME = 'CV GANESHA JAYA ARTHA';

  doc.addImage(image, 'PNG', 15, 10, 32, 32);

  // https://stackoverflow.com/questions/24912021/convert-a-image-url-to-pdf-using-jspdf

  let img = null;
  let w = 0;
  let h = 0;

  if (data?.photocopy?.name === 'Canon iRA 400') {
    w = 75;
    h = 75;
    img =
      'https://res.cloudinary.com/dkv1a9nmy/image/upload/catalogue/c3jkoezzgfx1ql7zc53z.jpg';
  } else {
    w = 50;
    h = 75;
    img =
      'https://res.cloudinary.com/dkv1a9nmy/image/upload/v1717559580/mrxpbcycbcxsadj1zciq.jpg';
  }

  var logo = await getDataUri(img);

  // COMPANY_NAME
  doc.setFontSize(20);
  doc.setFont('times', 'bold');
  doc.text(COMPANY_NAME, pageWidth / 2, 20, { align: 'center' });
  doc.setFontSize(11);
  doc.setFont('times', 'normal');
  doc.text('Jl. Jepara PPI Barat Blok B No. 10, Surabaya', pageWidth / 2, 25, {
    align: 'center',
  });
  doc.text('081393439398', pageWidth / 2, 30, {
    align: 'center',
  });
  doc.text('cvganeshajayaartha@gmail.com', pageWidth / 2, 35, {
    align: 'center',
  });

  doc.setFontSize(24);
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(1);
  doc.line(marginLR, 46, pageWidth - marginLR, 46);
  doc.setLineWidth(1.5);
  doc.line(marginLR, 48, pageWidth - marginLR, 48);

  // Nomor
  doc.setFontSize(12);
  const offeringNumber = data?.offering?.offering_number;
  doc.text(`Nomor: ${offeringNumber}`, marginLR, 56);
  doc.text('Perihal: Surat Penawaran', marginLR, 62);
  const DAY = dayjs();
  const day = DAY?.format('dddd');
  const date = DAY?.format('D');
  const month = DAY?.format('MMMM');
  const year = DAY?.format('YYYY');

  doc.text(`Surabaya, ${date} ${month} ${year}`, pageWidth - 56, 56);

  // TO RECIPIENT
  const to = `Kepada Yth.`;
  const companyName = `${data?.company?.name}\n${data?.company?.address}`;
  doc.text(to, marginLR, 78);
  doc.setFont('times', 'bold');
  doc.text(companyName, marginLR, 84, { maxWidth: pageWidth - 35 });
  doc.setFont('times', 'normal');

  doc.text('Dengan Hormat.', marginLR, 100);

  // CONTENT
  var text = `Bersama ini kami mengajukan surat penawaran harga Rental Mesin Fotocopy dengan spesifikasi Mesin dan harga sebagai berikut :`;
  var lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
  var splittedText = doc.splitTextToSize(text, pageWidth - 30);
  var lines = splittedText.length;
  var blockHeight = lines * lineHeight;
  var yPos = 114;
  var xPos = marginLR;
  doc.text(xPos, yPos, splittedText, { lineHeightFactor: 1.5 });
  yPos += blockHeight;

  // SPESIFICATION
  const specs = `Spesifikasi Mesin ${data?.photocopy?.name}`;
  lineHeight = doc.getLineHeight(specs) / doc.internal.scaleFactor;
  splittedText = doc.splitTextToSize(specs, pageWidth - 12);
  lines = splittedText.length;
  blockHeight = lines * lineHeight;
  yPos = 130;
  xPos = marginLR;
  doc.setFont('times', 'bold');
  doc.text(xPos, yPos, splittedText, { lineHeightFactor: 1.5 });

  doc.setFont('times', 'normal');
  const detailSpecs = data?.photocopy?.specification;
  lineHeight = doc.getLineHeight(detailSpecs) / doc.internal.scaleFactor;
  splittedText = doc.splitTextToSize(detailSpecs, pageWidth * 0.75);
  lines = splittedText.length;
  blockHeight = lines * lineHeight;
  doc.text(20, 136, detailSpecs, { lineHeightFactor: 1.25 });

  // RINCIAN HARGA RENTAL
  doc.setFont('times', 'bold');
  yPos = 136 + blockHeight + marginLR;

  doc.setFontSize(10);
  doc.text(
    20,
    yPos - 10,
    'GARANSI FREE MAINTENANCE SELAMA MASA SEWA BERLANGSUNG',
    { lineHeightFactor: 1.25 }
  );
  doc.setFontSize(12);

  doc.text('RINCIAN HARGA RENTAL', marginLR, yPos);
  doc.setFont('times', 'normal');
  const detailRental = `
1. Harga sewa per bulan Rp. ${data?.contract_value?.toLocaleString(
    'id-ID'
  )}, termasuk free cetak ${data?.free_printable} lembar
2. Untuk per lembar copy/print ke 2001 dan seterusnya akan dikenakan
    biaya Rp. ${data?.price_per_printable},- per lembar
3. Harga sewa ${data?.tax ? 'sudah' : 'belum'} termasuk PPN 12%
4. Harga sewa sudah termasuk Service, Sparepart, dan Toner
5. Untuk sewa Unit mesin fotokopi akan dikenakan Biaya tambahan berupa Ongkir, Biaya Instalasi dan
    Training senilai Rp. 250.000,- dapat dibayarkan setelah Unit selesai Terinstal
  `;
  lineHeight = doc.getLineHeight(detailRental) / doc.internal.scaleFactor;
  splittedText = doc.splitTextToSize(detailRental, pageWidth * 0.75);
  lines = splittedText.length;
  blockHeight = lines * lineHeight;
  doc.text(20, yPos, detailRental, {
    lineHeightFactor: 1.25,
  });
  doc.addImage(logo, 'PNG', 145, 120, w, h);
  // END
  text = `Demikian Surat penawaran ini kami sampaikan, dan atas perhatiannya kami ucapkan terimakasih.`;
  lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
  splittedText = doc.splitTextToSize(text, pageWidth - 30);
  lines = splittedText.length;
  xPos = marginLR;
  doc.text(xPos, yPos + blockHeight + 10, splittedText, {
    lineHeightFactor: 1.5,
  });

  const halfPage = pageWidth / 2;
  const text1 = Math.round(doc.getTextWidth('Hormat Kami'));
  const text2 = Math.round(doc.getTextWidth('CV Ganesha Jaya Artha'));
  const text3 = Math.round(doc.getTextWidth('Bella Astria SP'));
  const text4 = Math.round(doc.getTextWidth('Direktur'));
  const startText1 = (halfPage - text1) / 2 + halfPage;
  const startText2 = (halfPage - text2) / 2 + halfPage;
  const startText3 = (halfPage - text3) / 2 + halfPage;
  const startText4 = (halfPage - text4) / 2 + halfPage;
  const start = yPos + blockHeight + 20;
  doc.text('Hormat Kami,', startText1, start + 10);
  doc.text('CV Ganesha Jaya Artha', startText2, start + 15);
  doc.text('Bella Astria SP', startText3, start + 50);
  doc.text('Direktur', startText4, start + 55);
  doc.addImage(image2, 'PNG', startText2 + 5, start + 15, 32, 32);

  doc.setProperties({
    title: `${offeringNumber} | ${data?.company?.name} - ${data?.photocopy?.name}`,
  });
  // OPEN NEW TAB
  window.open(doc.output('bloburl', { filename: `Surat Penawaran.pdf` }));
};

export default OfferingPDF;
