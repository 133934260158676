import { gql } from '@apollo/client';

export const DEBTS = gql`
  query debts {
    debts {
      id
      did
      employee_id
      amount
      description
      transaction_date
      created_at
      updated_at
      employee {
        id
        name
      }
    }
  }
`;

export const CREATE_DEBT = gql`
  mutation createDebt($data: InDebt!) {
    createDebt(data: $data) {
      id
      did
      employee_id
      amount
      description
      transaction_date
      created_at
      updated_at
      employee {
        id
        name
      }
    }
  }
`;
