import jsPDF from 'jspdf';
import dayjs from 'dayjs';
import logo_gja from './logo-gja.png';
import locale_id from 'dayjs/locale/id';
dayjs.locale('id');

const getRomanCode = (month) => {
  switch (month) {
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    case 4:
      return 'IV';
    case 5:
      return 'V';
    case 6:
      return 'VI';
    case 7:
      return 'VII';
    case 8:
      return 'VIII';
    case 9:
      return 'IX';
    case 10:
      return 'X';
    case 11:
      return 'XI';
    case 12:
      return 'XII';
    default:
      return '-';
  }
};

const MouPDF = async (data) => {
  let doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    format: [210, 330],
  });
  const image = new Image();
  const marginLR = 20;
  image.src = logo_gja;

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const COMPANY_NAME = 'CV GANESHA JAYA ARTHA';

  doc.addImage(image, 'PNG', 15, 10, 32, 32);

  // COMPANY_NAME
  doc.setFontSize(20);
  doc.setFont('times', 'bold');
  doc.text(COMPANY_NAME, pageWidth / 2, 20, { align: 'center' });
  doc.setFontSize(11);
  doc.setFont('times', 'normal');
  doc.text('Jl. Jepara PPI Barat Blok B No. 10, Surabaya', pageWidth / 2, 25, {
    align: 'center',
  });
  doc.text('081393439398', pageWidth / 2, 30, {
    align: 'center',
  });
  doc.text('cvganeshajayaartha@gmail.com', pageWidth / 2, 35, {
    align: 'center',
  });

  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(1);
  doc.line(marginLR, 46, pageWidth - marginLR, 46);
  doc.setLineWidth(1.5);
  doc.line(marginLR, 48, pageWidth - marginLR, 48);
  let initX = 60;
  let space = 6;
  doc.setFont('times', 'bold');
  doc.setFontSize(20);
  doc.text('SURAT PERJANJIAN SEWA MESIN FOTOKOPI', pageWidth / 2, initX, {
    align: 'center',
  });

  // NOMOR
  const DAY = dayjs(data?.generate_mou_date);
  const day = DAY?.format('dddd');
  const date = DAY?.format('D');
  const month = DAY?.format('MMMM');
  const year = DAY?.format('YYYY');
  const _NO = `Nomor: ${data?.id}/SK/GJA/${getRomanCode(
    Number(DAY?.format('M'))
  )}/${year}`;
  doc.setFontSize(12);
  doc.setFont('times', 'normal');
  doc.text(_NO, pageWidth / 2, initX + space, {
    align: 'center',
  });

  const s1 = initX + space * 4;
  doc.setFont('times', 'bold');
  doc.text(
    `Pada Hari ini, ${day}, Tanggal ${date} Bulan ${month} Tahun ${year}.`,
    20,
    s1
  );
  doc.text('Kami yang bertanda tangan di bawah ini:', 20, s1 + space);

  const s2 = s1 + 24;
  const a = 30;
  const b = 35;
  const c = 60;
  const d = 62;
  doc.setFont('times', 'normal');
  doc.text('I. ', a, s2);
  doc.text('Nama', b, s2);
  doc.text(': ', c, s2);
  doc.text('BELLA ASTRIA SUMARYONO PUTRI', d, s2);

  doc.text('Jabatan', b, s2 + 8);
  doc.text(': ', c, s2 + 8);
  doc.text('Direktur', d, s2 + 8);

  doc.text('Perusahaan', b, s2 + 16);
  doc.text(': ', c, s2 + 16);
  doc.text('CV GANESHA JAYA ARTHA', d, s2 + 16);

  doc.text('Alamat', b, s2 + 24);
  doc.text(': ', c, s2 + 24);
  doc.text('Jl. Jepara PPI Barat B No. 10, Surabaya, Jawa Timur', d, s2 + 24);

  doc.setFont('times', 'bold');
  doc.text('Selanjutnya disebut PIHAK PERTAMA', a, s2 + 40);
  const s3 = s2 + 24 + 24 + 10;

  doc.setFont('times', 'normal');
  doc.text('II. ', a, s3 + 16);
  doc.text('Nama', b, s3 + 16);
  doc.text(': ', c, s3 + 16);
  doc.text(`${data?.company?.pic}`, d, s3 + 16);

  doc.text('Jabatan', b, s3 + 24);
  doc.text(': ', c, s3 + 24);
  doc.text(`${data?.company?.notes}`, d, s3 + 24);

  doc.text('Perusahaan', b, s3 + 32);
  doc.text(': ', c, s3 + 32);
  doc.text(`${data?.company?.name}`, d, s3 + 32);

  const address = data?.company?.address;
  let lineHeight = doc.getLineHeight(address) / doc.internal.scaleFactor;
  let splittedText = doc.splitTextToSize(address, pageWidth * 0.6);
  let lines = splittedText.length;
  let blockHeight = lines * lineHeight;

  doc.text('Alamat', b, s3 + 40);
  doc.text(': ', c, s3 + 40);
  doc.text(d, s3 + 40, splittedText, { lineHeightFactor: 1.75 });
  const endOfXAddress = s3 + 50 + blockHeight + 6;
  doc.setFont('times', 'bold');
  doc.text('Selanjutnya disebut PIHAK KEDUA', a, endOfXAddress);

  /** PAGE 2 */
  doc.addPage({ orientation: 'portrait', format: [210, 330] });
  doc.addImage(image, 'PNG', 15, 10, 32, 32);
  // COMPANY_NAME
  doc.setFontSize(20);
  doc.setFont('times', 'bold');
  doc.text(COMPANY_NAME, pageWidth / 2, 20, { align: 'center' });
  doc.setFontSize(11);
  doc.setFont('times', 'normal');
  doc.text('Jl. Jepara PPI Barat Blok B No. 10, Surabaya', pageWidth / 2, 25, {
    align: 'center',
  });
  doc.text('081393439398', pageWidth / 2, 30, {
    align: 'center',
  });
  doc.text('cvganeshajayaartha@gmail.com', pageWidth / 2, 35, {
    align: 'center',
  });

  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(1);
  doc.line(marginLR, 46, pageWidth - marginLR, 46);
  doc.setLineWidth(1.5);
  doc.line(marginLR, 48, pageWidth - marginLR, 48);
  initX = 60;
  space = 6;

  doc.setFontSize(12);
  const text1 =
    'Dengan ini menyatakan telah bersepakat membuat perjanjian sewa dan kontrak mesin fotokopi sebagai berikut :';
  lineHeight = doc.getLineHeight(text1) / doc.internal.scaleFactor;
  splittedText = doc.splitTextToSize(text1, pageWidth - 55);
  lines = splittedText.length;
  blockHeight = lines * lineHeight;
  doc.text(20, initX, splittedText, { lineHeightFactor: 1.5 });

  // PASAL 1
  doc.setFont('times', 'bold');
  doc.setFontSize(14);
  doc.text('Pasal 1', pageWidth / 2, initX + blockHeight + space * 1, {
    align: 'center',
  });
  doc.setFontSize(12);
  doc.setFont('times', 'normal');

  const clause1a = `Pihak I adalah pemilik 1 unit mesin fotokopi **${data?.photocopy?.name}** dan disewakan kepada Pihak II, sesuai kesepakatan kedua belah Pihak.
  `;
  const clause1b = `Pihak I wajib memberikan pelayanan servis dan perbaikan sesuai kontrak servis atas mesin tersebut diatas karena pemakaian Pihak II, baik karena diminta Pihak II ataupun inisiatif Pihak I sendiri untuk kelancaran operasional mesin fotokopi tersebut pada hari dan jam kerja Operasional yaitu hari Senin sampai Jum'at jam 8 pagi sampai jam 4 sore.`;
  const clause1c = `Pihak I wajib mengganti Toner dan Drum pada mesin tersebut apabila terjadi kerusakan diatas sesuai dengan pemakaian Pihak II, dan Sparepart lainya apabila terjadi kerusakan atas analisa Pihak I, apabila terjadi kendala pada mesin fotokopi diatas pukul 1 siang maka perbaikan akan dilakukan pada hari kerja operasional berikutnya.`;

  const isBoldOpen = (arrayLength, valueBefore = false) => {
    const isEven = arrayLength % 2 === 0;
    const result = valueBefore !== isEven;

    return result;
  };
  let startX = 35;
  let startY = initX + 24;
  let startXCached = startX;
  let boldOpen = false;

  let textMapped = doc.splitTextToSize(clause1a, pageWidth - 55);
  textMapped?.map((text) => {
    if (text) {
      const arrayOfNormalAndBoldText = text.split('**');
      arrayOfNormalAndBoldText.map((textItems, j) => {
        doc.setFont('times', boldOpen ? 'normal' : 'bold');

        if (j % 2 === 0) doc.setFont('times', boldOpen ? 'bold' : 'normal');

        doc.text(textItems, startX, startY);
        startX = startX + doc.getTextWidth(textItems);
        return true;
      });
      boldOpen = isBoldOpen(arrayOfNormalAndBoldText.length, boldOpen);
      startX = startXCached;
      startY += 7;
    }
    return true;
  });
  let lastInitX = initX + 24;
  doc.text('(a)', 25, lastInitX, {
    maxWidth: pageWidth - 55,
    lineHeightFactor: 1.5,
  });

  lineHeight = doc.getLineHeight(clause1a) / doc.internal.scaleFactor;
  lastInitX = initX + 36 + lineHeight;
  doc.text('(b)', 25, lastInitX, { maxWidth: pageWidth - 55 });
  doc.text(clause1b, 35, lastInitX, {
    maxWidth: pageWidth - 55,
    lineHeightFactor: 1.5,
  });

  lineHeight = doc.getLineHeight(clause1b) / doc.internal.scaleFactor;
  lastInitX = initX + 66 + lineHeight;
  doc.text('(c)', 25, lastInitX, { maxWidth: pageWidth - 55 });
  doc.text(clause1c, 35, lastInitX, {
    maxWidth: pageWidth - 55,
    lineHeightFactor: 1.5,
  });

  // PASAL 2
  doc.setFontSize(14);
  lineHeight = doc.getLineHeight(clause1c);
  lastInitX = initX + 66 + lineHeight + 26;
  const startClause2 = lastInitX;
  doc.setFont('times', 'bold');
  doc.text('Pasal 2', pageWidth / 2, startClause2, {
    align: 'center',
  });
  doc.setFontSize(12);
  doc.setFont('times', 'normal');

  const clause2a =
    'Yang termasuk dalam kontrak servis adalah pelayanan Pihak I kepada Pihak II atas jasa servis dan maintenance, penggantian Toner, Drum serta Sparepart elektronik lainya dan mekanik untuk mesin tersebut diatas.';
  const clause2b =
    'Yang tidak termasuk dalam kontrak servis adalah cover mesin (pecah/patah), paper tray (pecah/patah), huru hara serta kerusakan - kerusakan yang ditimbulkan oleh kelalaian Pihak II.';
  const clause2c =
    'Dalam hal terjadi kerusakan Sparepart atau barang - barang yang tidak termasuk dalam kontrak servis yang diakibatkan oleh kelalaian Pihak II dan membutuhkan penggantian maka Pihak II bersedia dikenakan biaya tambahan.';

  doc.text('(a)', 25, startClause2 + 8, { maxWidth: pageWidth - 55 });
  doc.text(clause2a, 35, startClause2 + 8, {
    maxWidth: pageWidth - 55,
    lineHeightFactor: 1.5,
  });

  lineHeight = doc.getLineHeight(clause2a);
  lastInitX = startClause2 + 18 + lineHeight;
  doc.text('(b)', 25, lastInitX, {
    maxWidth: pageWidth - 55,
  });
  doc.text(clause2b, 35, lastInitX, {
    maxWidth: pageWidth - 55,
    lineHeightFactor: 1.5,
  });

  lineHeight = doc.getLineHeight(clause2b);
  lastInitX = lastInitX + 24;

  doc.text('(c)', 25, lastInitX, {
    maxWidth: pageWidth - 55,
  });
  doc.text(clause2c, 35, lastInitX, {
    maxWidth: pageWidth - 55,
    lineHeightFactor: 1.5,
  });

  // ADD PAGE 3
  doc.addPage({ orientation: 'portrait', format: [210, 330] });
  doc.addImage(image, 'PNG', 15, 10, 32, 32);
  // COMPANY_NAME
  doc.setFontSize(20);
  doc.setFont('times', 'bold');
  doc.text(COMPANY_NAME, pageWidth / 2, 20, { align: 'center' });
  doc.setFontSize(11);
  doc.setFont('times', 'normal');
  doc.text('Jl. Jepara PPI Barat Blok B No. 10, Surabaya', pageWidth / 2, 25, {
    align: 'center',
  });
  doc.text('081393439398', pageWidth / 2, 30, {
    align: 'center',
  });
  doc.text('cvganeshajayaartha@gmail.com', pageWidth / 2, 35, {
    align: 'center',
  });

  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(1);
  doc.line(marginLR, 46, pageWidth - marginLR, 46);
  doc.setLineWidth(1.5);
  doc.line(marginLR, 48, pageWidth - marginLR, 48);

  // PASAL 3
  lineHeight = doc.getLineHeight(clause2b);

  const startClause3 = 60;
  doc.setFontSize(14);
  doc.setFont('times', 'bold');
  doc.text('Pasal 3', pageWidth / 2, startClause3, {
    align: 'center',
  });
  doc.setFontSize(12);
  doc.setFont('times', 'normal');
  const clause3a = `Harga sewa per mesin dan kontrak servis yang sudah disepakati Pihak I dan Pihak II adalah **Rp. ${data?.contract_value?.toLocaleString(
    'id-ID'
  )},-** perbulan, termasuk **Free ${data?.free_printable?.toLocaleString(
    'id-ID'
  )}** lembar copy/print BW, sedangkan untuk per lembar copy/print BW ke **${(
    data?.free_printable + 1
  )?.toLocaleString('id-ID')}** dan seterusnya akan dikenakan biaya **Rp. ${
    data?.price_per_printable
  },-** per lembar (**Harga tersebut ${
    data?.tax ? 'belum' : 'sudah'
  } termasuk PPN 12%**).`;
  const clause3b = `Pihak II wajib membayar harga sewa dan kontrak servis kepada Pihak I senilai harga sewa perbulan ditambah biaya kelebihan pemakaian dikalikan **Rp. ${data?.price_per_printable},-** selambat-lambatnya dua minggu setelah invoice diterima setiap bulannya.`;
  const clause3c = `Pihak II akan ${
    data?.installation_fee ? '**dikenakan**' : '**dibebaskan**'
  } Biaya tambahan dalam pengiriman Unit sewa mesin fotokopi, Biaya Instalasi dan training Pihak II disaat barang datang senilai **Rp. 250.000,-** dapat dibayarkan setelah mesin selesai di Instal.`;

  startY = startClause3 + 8;
  startX = 35;
  startXCached = startX;
  boldOpen = false;
  doc.text('(a)', 25, startY);
  textMapped = doc.splitTextToSize(clause3a, pageWidth - 55);
  textMapped?.map((text) => {
    if (text) {
      const arrayOfNormalAndBoldText = text.split('**');
      arrayOfNormalAndBoldText.map((textItems, j) => {
        doc.setFont('times', boldOpen ? 'normal' : 'bold');

        if (j % 2 === 0) doc.setFont('times', boldOpen ? 'bold' : 'normal');

        doc.text(textItems, startX, startY);
        startX = startX + doc.getTextWidth(textItems);
        return true;
      });
      boldOpen = isBoldOpen(arrayOfNormalAndBoldText.length, boldOpen);
      startX = startXCached;
      startY += 7;
    }
    return true;
  });

  startY = startClause3 + 28 + lineHeight;
  startX = 35;
  startXCached = startX;
  boldOpen = false;
  doc.text('(b)', 25, startY);
  textMapped = doc.splitTextToSize(clause3b, pageWidth - 55);
  textMapped?.map((text) => {
    if (text) {
      const arrayOfNormalAndBoldText = text.split('**');
      arrayOfNormalAndBoldText.map((textItems, j) => {
        doc.setFont('times', boldOpen ? 'normal' : 'bold');

        if (j % 2 === 0) doc.setFont('times', boldOpen ? 'bold' : 'normal');

        doc.text(textItems, startX, startY);
        startX = startX + doc.getTextWidth(textItems);
        return true;
      });
      boldOpen = isBoldOpen(arrayOfNormalAndBoldText.length, boldOpen);
      startX = startXCached;
      startY += 7;
    }
    return true;
  });

  startY = startY + 4;
  doc.text('(c)', 25, startY);
  startX = 35;
  startXCached = startX;
  boldOpen = false;
  textMapped = doc.splitTextToSize(clause3c, pageWidth - 55);
  textMapped?.map((text) => {
    if (text) {
      const arrayOfNormalAndBoldText = text.split('**');
      arrayOfNormalAndBoldText.map((textItems, j) => {
        doc.setFont('times', boldOpen ? 'normal' : 'bold');

        if (j % 2 === 0) doc.setFont('times', boldOpen ? 'bold' : 'normal');

        doc.text(textItems, startX, startY);
        startX = startX + doc.getTextWidth(textItems);
        return true;
      });
      boldOpen = isBoldOpen(arrayOfNormalAndBoldText.length, boldOpen);
      startX = startXCached;
      startY += 6;
    }
    return true;
  });

  // PASAL 4
  const startClause4 = startY + 12;
  doc.setFont('times', 'bold');
  doc.setFontSize(14);
  doc.text('Pasal 4', pageWidth / 2, startClause4, {
    align: 'center',
  });
  doc.setFontSize(12);
  const clause4a = `Perjanjian sewa ini berlaku selama ${
    data?.contract_periode === 12 ? '**1 (Satu) Tahun**' : '**6 (enam) Bulan**'
  }, terhitung sejak tanggal **${dayjs(data?.contract_date_origin).format(
    'DD MMMM YYYY'
  )} sampai dengan ${dayjs(data?.contract_date_origin)
    .add(data?.contract_periode, 'M')
    .format(
      'DD MMMM YYYY'
    )}**, dan selanjutnya akan diperpanjang secara otomatis selama ${
    data?.contract_periode === 12 ? '**1 (Satu) Tahun**' : '**6 (enam) Bulan**'
  } kembali sebelum ada permintaan stop kontrak sewa oleh Pihak II satu bulan sebelumnya atau permasalahan yang belum terselesaikan antara kedua belah pihak.`;
  startX = 35;
  startXCached = startX;
  boldOpen = false;
  startY = startY + 22;
  doc.setFont('times', 'normal');
  doc.text('(a)', 25, startY);
  textMapped = doc.splitTextToSize(clause4a, pageWidth - 55);
  textMapped?.map((text) => {
    if (text) {
      const arrayOfNormalAndBoldText = text.split('**');
      arrayOfNormalAndBoldText.map((textItems, j) => {
        doc.setFont('times', boldOpen ? 'normal' : 'bold');

        if (j % 2 === 0) doc.setFont('times', boldOpen ? 'bold' : 'normal');

        doc.text(textItems, startX, startY);
        startX = startX + doc.getTextWidth(textItems);
        return true;
      });
      boldOpen = isBoldOpen(arrayOfNormalAndBoldText.length, boldOpen);
      startX = startXCached;
      startY += 7;
    }
    return true;
  });

  startY = startY + 6;
  doc.text('(b)', 25, startY);
  const clause4b = `Apabila Pihak II memutuskan sepihak sebelum masa sewa berakhir maka akan dikenakan denda (Pinalti) pembayaran sisa dari kontrak pejanjian tersebut, senilai **Rp. ${data?.contract_value?.toLocaleString(
    'id-ID'
  )} x Sisa kontrak sampai ${dayjs(data?.contract_date_origin)
    .add(data?.contract_periode, 'M')
    .format(
      'DD MMMM YYYY'
    )}** dan kontrak perjanjian ini akan diperbarui setiap tahunnya.`;
  startX = 35;
  startXCached = startX;
  boldOpen = false;
  doc.setFont('times', 'normal');
  textMapped = doc.splitTextToSize(clause4b, pageWidth - 55);
  textMapped?.map((text) => {
    if (text) {
      const arrayOfNormalAndBoldText = text.split('**');
      arrayOfNormalAndBoldText.map((textItems, j) => {
        doc.setFont('times', boldOpen ? 'normal' : 'bold');

        if (j % 2 === 0) doc.setFont('times', boldOpen ? 'bold' : 'normal');

        doc.text(textItems, startX, startY);
        startX = startX + doc.getTextWidth(textItems);
        return true;
      });
      boldOpen = isBoldOpen(arrayOfNormalAndBoldText.length, boldOpen);
      startX = startXCached;
      startY += 7;
    }
    return true;
  });
  const clause4c = `Semua perselisihan yang timbul dari pejanjian ini, kedua belah pihak setuju untuk menyelesaikanya secara musyawarah untuk mufakat, dengan mengindahkan kelayakan dan kepatutan.`;
  startY = startY + 6;
  doc.text('(c)', 25, startY);
  startX = 35;
  startXCached = startX;
  boldOpen = false;
  doc.setFont('times', 'normal');
  textMapped = doc.splitTextToSize(clause4c, pageWidth - 55);
  textMapped?.map((text) => {
    if (text) {
      const arrayOfNormalAndBoldText = text.split('**');
      arrayOfNormalAndBoldText.map((textItems, j) => {
        doc.setFont('times', boldOpen ? 'normal' : 'bold');

        if (j % 2 === 0) doc.setFont('times', boldOpen ? 'bold' : 'normal');

        doc.text(textItems, startX, startY);
        startX = startX + doc.getTextWidth(textItems);
        return true;
      });
      boldOpen = isBoldOpen(arrayOfNormalAndBoldText.length, boldOpen);
      startX = startXCached;
      startY += 7;
    }
    return true;
  });

  /** PAGE 3 */
  /** PAGE 2 */
  doc.addPage({ orientation: 'portrait', format: [210, 330] });
  doc.addImage(image, 'PNG', 15, 10, 32, 32);
  // COMPANY_NAME
  doc.setFontSize(20);
  doc.setFont('times', 'bold');
  doc.text(COMPANY_NAME, pageWidth / 2, 20, { align: 'center' });
  doc.setFontSize(11);
  doc.setFont('times', 'normal');
  doc.text('Jl. Jepara PPI Barat Blok B No. 10, Surabaya', pageWidth / 2, 25, {
    align: 'center',
  });
  doc.text('081393439398', pageWidth / 2, 30, {
    align: 'center',
  });
  doc.text('cvganeshajayaartha@gmail.com', pageWidth / 2, 35, {
    align: 'center',
  });

  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(1);
  doc.line(marginLR, 46, pageWidth - marginLR, 46);
  doc.setLineWidth(1.5);
  doc.line(marginLR, 48, pageWidth - marginLR, 48);
  const endingText = `Demikianlah surat perjanjian dibuat 2 (Dua) rangkap dengan dibubuhi materai Rp 10.000,- dan masing-masing mempunyai kekuatan hukum yang sama, satu untuk Pihak I dan yang lain untuk Pihak II dengan beberapa rangkap.`;
  startX = 20;
  startXCached = startX;
  startY = 60;
  boldOpen = false;
  doc.setFontSize(12);
  doc.setFont('times', 'normal');
  lineHeight = doc.getLineHeight(endingText) / doc.internal.scaleFactor;
  splittedText = doc.splitTextToSize(endingText, pageWidth - 45);
  lines = splittedText.length;
  blockHeight = lines * lineHeight;
  doc.text(20, startY, splittedText, { lineHeightFactor: 1.5 });

  doc.setFont('times', 'bold');
  const halfPage = pageWidth / 2;
  const textSupervisor = 'Pihak I';
  const textSupervisorLength = Math.round(doc.getTextWidth(textSupervisor));
  const textSubordinate = 'Pihak II';
  const textSubordinateLength = Math.round(doc.getTextWidth(textSubordinate));
  const textSupervisorStartAt = (halfPage - textSupervisorLength) / 2;
  const textSubordinateStartAt =
    (halfPage - textSubordinateLength) / 2 + halfPage;

  doc.text(textSupervisor, textSupervisorStartAt, startY + 35);
  doc.text(textSubordinate, textSubordinateStartAt, startY + 35);

  const supervisorName = '(BELLA ASTRIA S.P)';
  const supervisorNameLength = Math.round(doc.getTextWidth(supervisorName));
  const subordinateName = `(${data?.company?.pic})`;
  const subordinateNameLength = Math.round(doc.getTextWidth(subordinateName));

  const textSupervisorNameStartAt = (halfPage - supervisorNameLength) / 2;
  const textSubordinateNameStartAt =
    (halfPage - subordinateNameLength) / 2 + halfPage;
  doc.text(supervisorName, textSupervisorNameStartAt, startY + 70);
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(
    textSupervisorNameStartAt,
    startY + 71,
    textSupervisorNameStartAt + supervisorNameLength,
    startY + 71
  );
  doc.text(subordinateName, textSubordinateNameStartAt, startY + 69);
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(
    textSubordinateNameStartAt,
    startY + 71,
    textSubordinateNameStartAt + subordinateNameLength,
    startY + 71
  );
  doc.setFont('times', 'normal');
  const supervisorTitle = 'Direktur';
  const supervisorTitleLength = Math.round(doc.getTextWidth(supervisorTitle));
  const subordinateTitle = `${data?.company?.notes}`;
  const subordinateTitleLength = Math.round(doc.getTextWidth(subordinateTitle));

  const subordinateTitle1 = subordinateTitle;
  let lineHeight1 =
    doc.getLineHeight(subordinateTitle1) / doc.internal.scaleFactor;
  let splittedText1 = doc.splitTextToSize(subordinateTitle1, pageWidth * 0.25);
  // let lines1 = splittedText1.length;
  let lines1 = Math.round(splittedText1.length);
  let blockHeight1 = lines * lineHeight;

  const textSupervisorTitleStartAt = (halfPage - supervisorTitleLength) / 2;
  const textSubordinateTitleStartAt =
    (halfPage - subordinateTitleLength) / 2 + halfPage;
  doc.text(supervisorTitle, textSupervisorTitleStartAt, startY + 75);
  // doc.text(splittedText1, textSubordinateTitleStartAt, startY + 75);
  doc.text(textSubordinateTitleStartAt, startY + 75, splittedText1, {
    lineHeightFactor: 1.75,
  });
  doc.setProperties({
    title: `${_NO} | ${data?.company?.name} - ${data?.photocopy?.name}`,
  });
  // OPEN NEW TAB
  window.open(doc.output('bloburl', { filename: `MoU | ${_NO}.pdf` }));
};

export default MouPDF;
