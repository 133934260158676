import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'universal-cookie/cjs';
import { gql, useLazyQuery } from '@apollo/client';

// import MonevCopyright from './MonevCopyright';
// import { AUTHENTICATE } from '../graphql/User';
import GlobalContext from '../utils/GlobalContext';

const AUTHENTICATE = gql`
  query authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      id
      name
      email
      role
      token
      eid
    }
  }
`;

const MyLogin = (props) => {
  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);
  let history = useHistory();

  const [authenticate] = useLazyQuery(AUTHENTICATE);
  // setGlobalLoading(false);

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <center>
          <img
            src='/logo.png'
            alt='logo'
            style={{
              width: '40%',
              marginBottom: '5px',
            }}
          />
          <Typography component='h1' variant='h6'>
            Aplikasi Purchase Order <br />
            CV Global Jaya Indo
          </Typography>
        </center>
        <Formik
          initialValues={{
            email: '',
            password: '',
            showPassword: false,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required('Harus diisi')
              .email('Format email tidak valid. Ex: example@email.com'),
            password: Yup.string().required('Harus diisi'),
          })}
          onSubmit={async ({ email, password }) => {
            try {
              setGlobalLoading(true);

              const data = await authenticate({
                variables: { email, password },
              });

              if (!data.data?.authenticate) {
                const message =
                  data.error?.graphQLErrors[0]?.message ||
                  'Gagal login, silahkan coba lagi';
                setSnack({
                  variant: 'error',
                  message,
                  opened: true,
                });
                setGlobalLoading(false);

                return false;
              }

              const cookie = new Cookies();
              cookie.set('id', data.data?.authenticate?.id);
              cookie.set('email', data.data?.authenticate?.email);
              cookie.set('role', data.data?.authenticate?.role);
              cookie.set('name', data.data?.authenticate?.name);
              cookie.set('eid', data.data?.authenticate?.eid);
              cookie.set('token', data.data?.authenticate?.token, {
                path: '/',
              });

              setGlobalLoading(false);

              history.push('/admin/dashboard');
              setSnack({
                variant: 'success',
                message: `Selamat Datang ${authenticate.name}`,
                opened: true,
              });
            } catch (error) {
              const message =
                error.message || 'Login gagal, silahkan coba lagi.';
              setSnack({
                variant: 'error',
                message,
                opened: true,
              });
              setGlobalLoading(false);
            }
          }}
          render={(props) => {
            let {
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            } = props;

            return (
              <Box
                component='form'
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  autoComplete='email'
                  name='email'
                  required
                  margin='normal'
                  fullWidth
                  autoFocus
                  id='email'
                  label='Email'
                  placeholder='Ex: example@email.com'
                  onChange={handleChange}
                  value={values.email}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email && errors.email}
                />
                <FormControl fullWidth margin='normal'>
                  <InputLabel htmlFor='outlined-adornment-password'>
                    Password *
                  </InputLabel>
                  <OutlinedInput
                    id='password'
                    name='password'
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange}
                    fullWidth
                    error={errors.password && touched.password}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() =>
                            setFieldValue('showPassword', !values.showPassword)
                          }
                          edge='end'
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Password'
                  />
                  {errors.password && touched.password && (
                    <FormHelperText error>{errors.password}</FormHelperText>
                  )}
                </FormControl>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                >
                  Login
                </Button>
              </Box>
            );
          }}
        />
      </Box>
      {/* <MonevCopyright sx={{ mt: 8, mb: 4 }} /> */}
    </Container>
  );
};

export default MyLogin;
