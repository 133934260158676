import React from 'react';
import {
  Box,
  Paper,
  Grid,
  Typography,
  FormHelperText,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import MyBreadcrumbs from '../MyBreadcrumbs';
import { COMPANIES } from '../../graphql/Company';
import { PHOTOCOPIES } from '../../graphql/Photocopy';
import { EMPLOYEES } from '../../graphql/Employee';
import { CREATE_RENTAL } from '../../graphql/Rental';
import GlobalContext from '../../utils/GlobalContext';

const RentalForm = () => {
  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);
  let history = useHistory();
  const [companies, setCompanies] = React.useState([]);
  const [company, setCompany] = React.useState(null);
  const [photocopies, setPhotocopies] = React.useState([]);
  const [photocopy, setPhotocopy] = React.useState(null);
  const [employees, setEmployees] = React.useState([]);
  const [employee, setEmployee] = React.useState(null);
  const [data, setData] = React.useState({
    initialCounter: {
      value: 0,
      error: false,
    },
    contractDate: {
      value: new Date(),
      error: false,
    },
    contractPeriode: {
      value: null,
      error: false,
    },
    contractValue: {
      value: 0,
      error: false,
    },
    freePrintable: {
      value: 0,
      error: false,
    },
    pricePerPrintable: {
      value: 0,
      error: false,
    },
    tax: {
      value: true,
      error: false,
    },
    installationFee: {
      value: true,
      error: false,
    },
    technician: {
      value: null,
      error: false,
    },
  });

  const { data: dataCompanies } = useQuery(COMPANIES, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting companies:', e),
  });

  React.useEffect(() => {
    if (dataCompanies) {
      setCompanies(dataCompanies.companies);
    }
  }, [dataCompanies]);

  const { data: dataPhotocopies } = useQuery(PHOTOCOPIES, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting photocopies:', e),
  });

  React.useEffect(() => {
    if (dataPhotocopies) {
      setPhotocopies(dataPhotocopies.photocopies);
    }
  }, [dataPhotocopies]);

  const { data: dataEmployees } = useQuery(EMPLOYEES, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting employees:', e),
  });

  React.useEffect(() => {
    if (dataEmployees) {
      setEmployees(dataEmployees.employees);
    }
  }, [dataEmployees]);

  const [insertRental] = useMutation(CREATE_RENTAL, {
    onCompleted: (d) => {
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data rental fotokopi berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      history.push('/admin/rental');
    },
  });

  const endOfContract = () => {
    if (data?.contractPeriode?.value && data?.contractDate?.value) {
      return dayjs(data?.contractDate?.value)
        .add(data?.contractPeriode?.value, 'months')
        .format('DD/MM/YYYY');
    }
  };

  /* SUBMIT DATA */
  const handleSubmit = () => {
    const params = {
      company_id: company?.value,
      photocopy_id: photocopy?.value,
      employee_id: employee?.value,
      technician_name: data?.technician?.value,
      contract_date: data?.contractDate?.value,
      contract_periode: data?.contractPeriode?.value,
      initial_counter: Number(data?.initialCounter?.value),
      contract_value: Number(data?.contractValue?.value),
      free_printable: Number(data?.freePrintable?.value),
      price_per_printable: Number(data?.pricePerPrintable?.value),
      tax: data?.tax?.value,
      installation_fee: data?.installationFee?.value,
    };

    insertRental({ variables: { data: params } });
  };

  const handleCheckForm = () => {
    if (!employee) setEmployee({ ...employee, error: true });

    if (!company) setCompany({ ...company, error: true });

    if (!photocopy) setPhotocopy({ ...photocopy, error: true });

    const keys = Object.keys(data).filter(
      (x) => !['initialCounter', 'tax', 'installationFee'].includes(x)
    );

    keys.forEach((x) => {
      const _DATA = { ...data };
      if (!_DATA[x]?.value) {
        _DATA[x].error = true;

        setData({ ..._DATA });
      } else {
        setData({ ..._DATA });
      }
    });

    if (!photocopy?.error && !company?.error && !employee?.error) {
      const keys = Object.keys(data).filter((x) => x !== 'initialCounter');
      const errors = keys.map((x) => data[x].error);
      const total = errors.filter((x) => x).length;
      if (total === 0) handleSubmit();
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs data={['Fotokopi', 'Tambah Data']} />
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ pb: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            sx={{
              padding: 2,
              margin: 'auto',
              maxWidth: '75%',
            }}
          >
            <Grid item xs={12} sm container>
              <Grid
                item
                xs
                container
                direction='column'
                spacing={2}
                sx={{ pb: 2 }}
              >
                <Grid item xs>
                  <Typography gutterBottom variant='subtitle2'>
                    Perusahaan
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    disableClearable
                    options={companies.map((option) => option.name)}
                    value={companies?.name}
                    onInputChange={(_, v) => {
                      const client = companies.find((x) => x.name === v);

                      if (client) {
                        setCompany({
                          value: client.id,
                          error: false,
                          name: v,
                          address: client?.address,
                          pic: client?.pic,
                          phone: client?.phone,
                        });
                      }
                    }}
                    onClick
                    renderInput={(params) => (
                      <TextField {...params} label='Nama Perusahaan' />
                    )}
                  />
                  {company?.error && (
                    <FormHelperText error>Harus diisi.</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    autoComplete='address'
                    name='address'
                    fullWidth
                    id='address'
                    label='Alamat'
                    placeholder='Alamat'
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={company?.address}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    autoComplete='pic'
                    name='pic'
                    fullWidth
                    id='pic'
                    label='PIC'
                    placeholder='PIC'
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={company?.pic}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    autoComplete='phone'
                    name='phone'
                    fullWidth
                    id='phone'
                    label='No. Telpon'
                    placeholder='No. Telpon'
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={company?.phone}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ pb: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            sx={{
              padding: 2,
              margin: 'auto',
              maxWidth: '75%',
            }}
          >
            <Grid item xs={12} sm container>
              <Grid
                item
                xs
                container
                direction='column'
                spacing={2}
                sx={{ pb: 2 }}
              >
                <Grid item xs>
                  <Typography gutterBottom variant='subtitle2'>
                    Mesin Fotokopi
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    disableClearable
                    options={photocopies.map((option) => option.name)}
                    value={photocopies?.name}
                    onInputChange={(_, v) => {
                      const client = photocopies.find((x) => x.name === v);

                      if (client) {
                        setPhotocopy({
                          value: client.id,
                          error: false,
                          name: v,
                          serial_number: client?.serial_number,
                          type: client?.type,
                        });
                      }
                    }}
                    onClick
                    renderInput={(params) => (
                      <TextField {...params} label='Nama Mesin' />
                    )}
                  />
                  {photocopy?.error && (
                    <FormHelperText error>Harus diisi.</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    autoComplete='serial_number'
                    name='serial_number'
                    fullWidth
                    id='serial_number'
                    label='No. Seri'
                    placeholder='No. Seri'
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={photocopy?.serial_number}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    autoComplete='type'
                    name='type'
                    fullWidth
                    id='type'
                    label='Tipe'
                    placeholder='Tipe'
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={photocopy?.type}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    id='intialCounter'
                    name='intialCounter'
                    type='number'
                    label='Counter Awal'
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    value={data?.initialCounter?.value}
                    onChange={(e) => {
                      setData({
                        ...data,
                        initialCounter: {
                          ...data?.initialCounter,
                          value: e?.target?.value,
                          error: false,
                        },
                      });
                    }}
                    error={Boolean(data?.initialCounter?.error)}
                    helperText={
                      Boolean(data?.initialCounter?.error) && 'Harus diisi'
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ pb: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            sx={{
              padding: 2,
              margin: 'auto',
              maxWidth: '75%',
            }}
          >
            <Grid item xs={12} sm container>
              <Grid
                item
                xs
                container
                direction='column'
                spacing={2}
                sx={{ pb: 2 }}
              >
                <Grid item xs>
                  <Typography gutterBottom variant='subtitle2'>
                    Kontrak
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12} container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id='contractDate'
                    name='contractDate'
                    label='Tanggal Kontrak'
                    type='date'
                    value={data?.contractDate?.value}
                    error={data?.contractDate?.error}
                    helperText={data?.contractDate?.error && 'Harus Diisi'}
                    onChange={(e) => {
                      setData({
                        ...data,
                        contractDate: {
                          ...data?.contractDate,
                          value: e?.target?.value,
                          error: false,
                        },
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id='company'>Masa Kontrak</InputLabel>
                    <Select
                      labelId='contractPeriode'
                      id='contractPeriode'
                      name='contractPeriode'
                      label='Masa Kontrak'
                      value={company?.value}
                      onChange={(e) => {
                        setData({
                          ...data,
                          contractPeriode: {
                            ...data?.contractPeriode,
                            value: e?.target?.value,
                            error: false,
                          },
                        });
                      }}
                    >
                      <MenuItem disabled selected value=''>
                        Pilih
                      </MenuItem>
                      <MenuItem value={6}>6 Bulan</MenuItem>
                      <MenuItem value={12}>12 Bulan</MenuItem>
                    </Select>
                    {company?.error && (
                      <FormHelperText error>Harus diisi.</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id='endOfContract'
                    name='endOfContract'
                    label='Tanggal Kontrak Berakhir'
                    type='text'
                    value={endOfContract()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumberFormat
                    customInput={TextField}
                    label='Nilai Kontrak'
                    variant='outlined'
                    isNumericString
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix='Rp. '
                    name='contractValue'
                    fullWidth
                    id='contractValue'
                    value={data?.contractValue?.value}
                    error={data?.contractValue?.error}
                    helperText={data?.contractValue?.error && 'Harus Diisi'}
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      setData({
                        ...data,
                        contractValue: {
                          ...data?.contractValue,
                          value: floatValue,
                          error: false,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    id='freePrintable'
                    name='freePrintable'
                    type='number'
                    label='Free Cetak'
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    value={data?.freePrintable?.value}
                    onChange={(e) => {
                      setData({
                        ...data,
                        freePrintable: {
                          ...data?.freePrintable,
                          value: e?.target?.value,
                          error: false,
                        },
                      });
                    }}
                    error={Boolean(data?.freePrintable?.error)}
                    helperText={
                      Boolean(data?.freePrintable?.error) && 'Harus diisi'
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumberFormat
                    customInput={TextField}
                    label='Lebih Cetak'
                    variant='outlined'
                    isNumericString
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix='Rp. '
                    name='pricePerPrintable'
                    fullWidth
                    id='pricePerPrintable'
                    value={data?.pricePerPrintable?.value}
                    error={data?.pricePerPrintable?.error}
                    helperText={data?.pricePerPrintable?.error && 'Harus Diisi'}
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      setData({
                        ...data,
                        pricePerPrintable: {
                          ...data?.pricePerPrintable,
                          value: floatValue,
                          error: false,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id='tax'>PPN 12%</InputLabel>
                    <Select
                      labelId='tax'
                      id='tax'
                      name='tax'
                      label='PPN 12%'
                      value={data?.tax?.value}
                      onChange={(e) => {
                        setData({
                          ...data,
                          tax: {
                            ...data?.tax,
                            value: e?.target?.value,
                          },
                        });
                      }}
                    >
                      <MenuItem disabled selected value=''>
                        Pilih
                      </MenuItem>
                      <MenuItem value={true}>Ya</MenuItem>
                      <MenuItem value={false}>Tidak</MenuItem>
                    </Select>
                    {data?.tax?.error && (
                      <FormHelperText error>Harus diisi.</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id='installationFee'>
                      Biaya Instalasi
                    </InputLabel>
                    <Select
                      labelId='installationFee'
                      id='installationFee'
                      name='installationFee'
                      label='Biaya Instalasi'
                      value={data?.installationFee?.value}
                      onChange={(e) => {
                        setData({
                          ...data,
                          installationFee: {
                            ...data?.installationFee,
                            value: e?.target?.value,
                          },
                        });
                      }}
                    >
                      <MenuItem disabled selected value=''>
                        Pilih
                      </MenuItem>
                      <MenuItem value={true}>Ya</MenuItem>
                      <MenuItem value={false}>Tidak</MenuItem>
                    </Select>
                    {data?.installationFee?.error && (
                      <FormHelperText error>Harus diisi.</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    disableClearable
                    options={employees.map((option) => option.name)}
                    value={employees?.name}
                    onInputChange={(_, v) => {
                      const emp = employees.find((x) => x.name === v);

                      if (emp) {
                        setEmployee({
                          value: emp.id,
                          error: false,
                          name: v,
                        });
                      }
                    }}
                    onClick
                    renderInput={(params) => (
                      <TextField {...params} label='Nama Marketing' />
                    )}
                  />
                  {employee?.error && (
                    <FormHelperText error>Harus diisi.</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    autoComplete='technician'
                    name='technician'
                    fullWidth
                    id='technician'
                    label='Teknisi'
                    placeholder='Teknisi'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={data?.technician?.value}
                    onChange={(e) => {
                      setData({
                        ...data,
                        technician: {
                          ...data?.technician,
                          value: e?.target?.value,
                          error: false,
                        },
                      });
                    }}
                    error={Boolean(data?.technician?.error)}
                    helperText={
                      Boolean(data?.technician?.error) && 'Harus diisi'
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption'>
                    Jika sewa, PPN di klik TIDAK maka Harga sewa Belum termasuk
                    PPN 12%
                  </Typography>
                  <br />
                  <Typography variant='caption'>
                    Jika sewa, PPN di klik YA maka Harga sewa sudah termasuk PPN
                    12%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                onClick={handleCheckForm}
                variant='contained'
                sx={{ mt: 2, mr: 1 }}
              >
                Simpan
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default RentalForm;
