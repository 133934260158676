import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo_gja from './logo-gja.png';
import dayjs from 'dayjs';
import locale_id from 'dayjs/locale/id';
dayjs.locale('id');

function addWaterMark(doc, image, x, y) {
  var totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({ opacity: 0.2 }));
    doc.addImage(image, 'PNG', x, y - 15, 100, 100);

    doc.restoreGraphicsState();
  }

  return doc;
}

const OfferingPDF = async (rental, invoice, initial) => {
  let doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    format: [210, 330],
  });
  const image = new Image();
  image.src = logo_gja;

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  let gen_date = invoice?.generate_at
    ? invoice?.generate_at
    : invoice?.created_at;

  if (initial) gen_date = dayjs().format('YYYY-MM-DD');
  const date = dayjs(gen_date).format('DD MMMM YYYY');
  const due_date = dayjs(invoice?.due_date_origin)
    .add(1, 'month')
    .subtract(1, 'day')
    .format('DD MMMM YYYY');
  const start = dayjs(invoice?.due_date_origin)
    .subtract(1, 'month')
    .format('DD MMMM YYYY');
  const end = dayjs(invoice?.due_date_origin)
    .subtract(1, 'day')
    .format('DD MMMM YYYY');
  doc.setFontSize(22);
  doc.setFont('times', 'bold');
  doc.text('INVOICE RENTAL', 15, 20);
  doc.text('INVOICE RENTAL', 15, 20);
  doc.setFontSize(12);
  doc.setFont('times', 'normal');
  doc.text(`No. ${invoice.invoice_number}`, 15, 25);
  doc.text(`Tanggal: ${date}`, 15, 35);
  doc.text(`Jatuh Tempo: ${due_date}`, 15, 42);
  doc.text(`Periode: ${start} - ${end}`, 15, 49);

  doc.text('Kepada:', 125, 28);
  doc.setFont('times');
  var text = `${rental.company?.name} \n${rental.company?.address}`;
  var lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
  var splittedText = doc.splitTextToSize(text, 50);
  var lines = splittedText.length;
  var blockHeight = lines * lineHeight;
  var yPos = 35;
  var xPos = 125;
  doc.setFontSize(14);
  doc.setFont('times', 'bold');
  doc.text(xPos, yPos, splittedText, { lineHeightFactor: 1.5 });
  yPos += blockHeight;
  doc.setFontSize(12);
  doc.setFont('times', 'normal');
  const contractValue = rental?.contract_value;
  const initialCounter = invoice?.initial_counter;
  const finalCounter = invoice?.final_counter;
  const freePrintable = rental?.free_printable;
  const installationFee =
    rental?.installation_fee &&
    invoice?.contract_to === 1 &&
    !rental?.print_installation_fee
      ? 250000
      : 0;
  let overPrint = finalCounter - initialCounter - freePrintable;
  if (overPrint < 0) overPrint = 0;
  const pricePerPrint = rental?.price_per_printable;
  const overpricePrint = overPrint * pricePerPrint;
  const subtotal = contractValue + overpricePrint;
  let tax = 0;
  let grandTotal = 0;
  let arrayCalculation = [];
  if (rental?.tax) {
    tax = Math.round(subtotal * 0.11);
    grandTotal = subtotal + tax + installationFee;
    arrayCalculation = [
      [
        {
          content: 'PPn 12%',
          colSpan: 4,
          styles: { fontStyle: 'bold', halign: 'right' },
        },
        {
          content: `Rp. ${tax?.toLocaleString('id')}`,
        },
      ],
      [
        {
          content: 'Total',
          colSpan: 4,
          styles: { fontStyle: 'bold', halign: 'right' },
        },
        {
          content: `Rp. ${grandTotal?.toLocaleString('id')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
    ];
  } else {
    grandTotal = subtotal + tax + installationFee;
    arrayCalculation = [
      [
        {
          content: 'Total',
          colSpan: 4,
          styles: { fontStyle: 'bold', halign: 'right' },
        },
        {
          content: `Rp. ${grandTotal?.toLocaleString('id')}`,
        },
      ],
    ];
  }

  if (
    rental?.installation_fee &&
    invoice?.contract_to === 1 &&
    !rental?.print_installation_fee
  ) {
    arrayCalculation.unshift([
      {
        content: 'Biaya Instalasi & Ongkir',
        colSpan: 4,
        styles: { fontStyle: 'bold', halign: 'right' },
      },
      {
        content: `Rp. ${installationFee?.toLocaleString('id')}`,
      },
    ]);
  }

  autoTable(doc, {
    margin: { left: 15 },
    startY: yPos + 5,
    theme: 'grid',
    headStyles: { fillColor: [47, 84, 150], textColor: 'white', fontSize: 12 },
    bodyStyles: { textColor: 'black' },
    head: [
      {
        no: 'No.',
        description: 'Deskripsi',
        qty: 'Jumlah',
        price: 'Harga',
        total: 'Total',
      },
    ],
    body: [
      [
        { content: '1' },
        { content: 'Pokok Sewa Per Bulan', styles: { fontStyle: 'bold' } },
        { content: 1 },
        { content: `Rp. ${contractValue?.toLocaleString('id-ID')}` }, // rental?.contract_value
        { content: `Rp. ${contractValue?.toLocaleString('id-ID')}` }, // rental?.contract_value
      ],
      [
        { content: '2' },
        { content: 'Counter Awal:' },
        { content: initialCounter?.toLocaleString('id-ID') }, // invoice?.initial_counter
      ],
      [
        { content: '' },
        { content: 'Counter Akhir:' },
        { content: finalCounter?.toLocaleString('id-ID') }, // invoice?.final_counter
      ],
      [
        { content: '3' },
        { content: 'Kelebihan Counter BW', styles: { fontStyle: 'bold' } },
        { content: overPrint?.toLocaleString('id-ID') }, // invoice?.final_counter - invoice?.initial_counter - rental?.free_printable
        { content: `Rp. ${pricePerPrint}` }, // rental?.price_per_printable
        { content: `Rp. ${overpricePrint.toLocaleString('id-ID')}` }, // invoice?.final_counter - invoice?.initial_counter - rental?.free_printable * rental?.price_per_printable
      ],
      [
        {
          content: '',
          colSpan: 5,
        },
      ],
      [
        {
          content: 'Subtotal',
          colSpan: 4,
          styles: { fontStyle: 'bold', halign: 'right' },
        },
        {
          content: `Rp. ${subtotal?.toLocaleString('id')}`,
        },
      ],
      ...arrayCalculation,
    ],
  });

  const lastTable = doc.lastAutoTable.finalY + 10;
  doc.setFont('times', 'bold');
  doc.text('Sistem Pembayaran', 15, lastTable);
  doc.setFont('times', 'normal');
  doc.text('1. Tunai', 15, lastTable + 6);
  doc.text('2. Transfer Bank: ', 15, lastTable + 12);
  doc.setFont('times', 'bold');
  doc.text('Bank BCA 4680348001', 46, lastTable + 12);
  doc.setFont('times', 'normal');
  doc.text('a.n BELLA ASTRIA SUMARYONO PUTRI', 15, lastTable + 18);
  doc.text(
    'Pertanyaan dan informasi dapat diajukan melalui:',
    15,
    lastTable + 26
  );
  doc.text(
    '0819-1550-1248 atau cvganeshajayaartha@gmail.com',
    15,
    lastTable + 32
  );
  doc.text(
    'Catatan: Setiap pembayaran yang sudah dilakukan mohon sertakan bukti pembayaran',
    15,
    lastTable + 40
  );
  doc.text(
    'Terima kasih atas kepercayaan Anda kepada perusahaan kami.',
    15,
    lastTable + 46
  );
  doc.text(
    'Semoga hubungan kerjasama ini dapat berlanjut.',
    15,
    lastTable + 52
  );
  const halfPage = pageWidth / 2;
  doc = addWaterMark(doc, image, halfPage / 2, 85);
  doc.setProperties({
    author: 'Ganesha Jaya Artha',
    title: `${invoice?.invoice_number} | ${rental?.company?.name}`,
    filename: `INVOICE - ${invoice?.invoice_number} | ${rental?.company?.name}.pdf`,
  });
  // OPEN NEW TAB
  window.open(
    doc.output('bloburl', {
      filename: `INVOICE - ${invoice?.invoice_number} | ${rental?.company?.name}.pdf`,
    })
  );
};

export default OfferingPDF;
