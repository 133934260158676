import { gql } from '@apollo/client';

export const ORDER_BY_QUARTERLY = gql`
  query findOrderByQuarterly($quarterly: Int!, $status: String, $year: Int!) {
    findOrderByQuarterly(quarterly: $quarterly, status: $status, year: $year) {
      id
      oid
      company
      invoice_number
      quarterly
      status
      payment_type
      payment_method
      total_payment_fee
      tax
      tax_type
      tax_period
      admin_fee
      total_loan
      grand_total
      total_budget
      printed_at
      created_at
      total_sales
      is_custom
      year
      employee {
        id
        name
      }
      client {
        id
        name
        regency
      }
      order_products {
        id
        name
        selling_price
        qty
        sub_total
      }
    }
  }
`;

export const ORDER_BY_OID = gql`
  query findOrderByOid($oid: String!) {
    findOrderByOid(oid: $oid) {
      id
      oid
      client_id
      employee_id
      company
      invoice_number
      quarterly
      status
      payment_type
      payment_method
      total_payment_fee
      tax
      tax_type
      tax_period
      total
      admin_fee
      tax_admin_fee
      total_down_payment
      total_discount
      total_profit
      total_sales
      total_base_price
      total_extra_base_price
      total_delivery_fee
      total_budget
      sub_total
      refund
      total_loan
      grand_total
      printed_at
      approved
      approved_at
      confirmed_at
      created_at
      updated_at
      is_custom
      total_custom_sales
      year
      total_pph_fee
      total_va_fee
      total_transaction_fee
      order_products {
        id
        name
        base_price
        budget
        description
        selling_price
        delivery_fee
        discount
        previousStatus
        profit
        profit_percentage
        qty
        sub_total
        status
        markup_price
        markuped
        extra_base_price
        total_extra_base_price
        printed
        printed_at
        type {
          id
          name
        }
        unit {
          id
          name
        }
      }
      employee {
        id
        name
      }
      client {
        id
        name
        regency
        address
      }
      travel_document {
        id
        order_id
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
        updated_at
      }
      order_payments {
        id
        pid
        order_id
        payment_order
        payment_fee
        payment_reference
        mutation
        tax
        difference
        payment_at
        created_at
        updated_at
        va_fee
        pph_fee
        transaction_fee
      }
      loans {
        id
        amount
        loan_at
      }
      down_payments {
        id
        amount
        down_payment_at
      }
    }
  }
`;

export const CREATE_TRAVEL_DOC = gql`
  mutation createTravelDocument($data: InTravelDocument!) {
    createTravelDocument(data: $data) {
      id
      order_id
      reference_number
      driver_name
      driver_phone
      shipped_with
      licence_plat
      created_at
      updated_at
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($data: InOrder!) {
    createOrder(data: $data) {
      id
      oid
      client_id
      employee_id
      company
      invoice_number
      quarterly
      status
      payment_type
      payment_method
      total_payment_fee
      tax
      tax_type
      tax_period
      total
      admin_fee
      tax_admin_fee
      total_down_payment
      total_discount
      total_profit
      total_sales
      total_base_price
      total_budget
      sub_total
      refund
      total_loan
      grand_total
      printed_at
      approved
      approved_at
      confirmed_at
      created_at
      updated_at
      is_custom
      total_custom_sales
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder($id: ID!, $data: InOrder) {
    updateOrder(id: $id, data: $data) {
      id
      oid
      client_id
      employee_id
      status
      quarterly
      total_base_price
      total_extra_base_price
      payment_type
      payment_method
      total_payment_fee
      total_discount
      total_sales
      tax_type
      invoice_number
      tax
      total_loan
      total_down_payment
      sub_total
      grand_total
      total_profit
      total_budget
      company
      created_at
      updated_at
      printed_at
      approved
      approved_at
      confirmed_at
      is_custom
      total_custom_sales
      total_delivery_fee
      order_products {
        id
        name
        base_price
        budget
        description
        selling_price
        delivery_fee
        discount
        previousStatus
        profit
        profit_percentage
        qty
        sub_total
        status
        markup_price
        markuped
        type {
          id
          name
        }
        unit {
          id
          name
        }
      }
      employee {
        id
        name
      }
      client {
        id
        name
        regency
      }
      travel_document {
        id
        order_id
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
        updated_at
      }
      loans {
        id
        amount
        loan_at
      }
      down_payments {
        id
        amount
        down_payment_at
      }
    }
  }
`;

export const CREATE_PAYMENTS = gql`
  mutation createPayments($oid: String!, $data: InPayment!) {
    createPayments(oid: $oid, data: $data) {
      id
      oid
      client_id
      employee_id
      status
      quarterly
      total_base_price
      total_payment_fee
      payment_type
      payment_method
      total_discount
      total_sales
      tax_type
      invoice_number
      tax
      total_loan
      total_down_payment
      sub_total
      grand_total
      total_profit
      total_budget
      company
      created_at
      updated_at
      printed_at
      approved
      approved_at
      confirmed_at
      is_custom
      total_custom_sales
      order_products {
        id
        name
        base_price
        budget
        description
        selling_price
        delivery_fee
        discount
        previousStatus
        profit
        profit_percentage
        qty
        sub_total
        status
        markup_price
        markuped
        type {
          id
          name
        }
        unit {
          id
          name
        }
      }
      employee {
        id
        name
      }
      client {
        id
        name
        regency
      }
      travel_document {
        id
        order_id
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
        updated_at
      }
      order_payments {
        id
        pid
        order_id
        payment_order
        payment_fee
        payment_reference
        mutation
        difference
        payment_at
        created_at
        updated_at
      }
      loans {
        id
        amount
        loan_at
      }
      down_payments {
        id
        amount
        down_payment_at
      }
    }
  }
`;

export const CONFIRM_PAYMENT = gql`
  mutation confirmPayment(
    $oid: String!
    $total_payment_fee: Double!
    $method: String!
  ) {
    confirmPayment(
      oid: $oid
      total_payment_fee: $total_payment_fee
      method: $method
    ) {
      id
      oid
      client_id
      employee_id
      status
      quarterly
      total_payment_fee
      total_base_price
      payment_type
      tax_type
      invoice_number
      tax
      total_loan
      total_down_payment
      sub_total
      grand_total
      total_discount
      total_sales
      total_profit
      total_budget
      company
      created_at
      updated_at
      printed_at
      approved
      approved_at
      confirmed_at
      is_custom
      total_custom_sales
      order_products {
        id
        name
        base_price
        budget
        description
        selling_price
        delivery_fee
        discount
        previousStatus
        profit
        profit_percentage
        qty
        sub_total
        status
        markup_price
        markuped
        type {
          id
          name
        }
        unit {
          id
          name
        }
      }
      employee {
        id
        name
      }
      client {
        id
        name
        regency
      }
      travel_document {
        id
        order_id
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
        updated_at
      }
      order_payments {
        id
        pid
        order_id
        payment_order
        payment_fee
        payment_reference
        mutation
        difference
        payment_at
        created_at
        updated_at
      }
      loans {
        id
        amount
        loan_at
      }
      down_payments {
        id
        amount
        down_payment_at
      }
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  mutation updatePayment($oid: String!, $data: InPayment!) {
    updatePayment(oid: $oid, data: $data) {
      id
      oid
      client_id
      employee_id
      company
      invoice_number
      quarterly
      status
      payment_type
      payment_method
      total_payment_fee
      tax
      tax_type
      tax_period
      total
      admin_fee
      tax_admin_fee
      total_down_payment
      total_discount
      total_profit
      total_sales
      total_base_price
      total_budget
      sub_total
      refund
      total_loan
      grand_total
      printed_at
      approved
      approved_at
      confirmed_at
      created_at
      updated_at
      is_custom
      total_custom_sales
      order_products {
        id
        name
        base_price
        budget
        description
        selling_price
        delivery_fee
        discount
        previousStatus
        profit
        profit_percentage
        qty
        sub_total
        status
        markup_price
        markuped
        type {
          id
          name
        }
        unit {
          id
          name
        }
      }
      employee {
        id
        name
      }
      client {
        id
        name
        regency
        address
      }
      travel_document {
        id
        order_id
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
        updated_at
      }
      order_payments {
        id
        pid
        order_id
        payment_order
        payment_fee
        payment_reference
        mutation
        difference
        payment_at
        created_at
        updated_at
      }
      loans {
        id
        amount
        loan_at
      }
      down_payments {
        id
        amount
        down_payment_at
      }
    }
  }
`;

export const UPDATE_INVOICE_DATE = gql`
  mutation updateInvoiceOrderDate($oid: String!, $printed_at: DateTime!) {
    updateInvoiceOrderDate(oid: $oid, printed_at: $printed_at) {
      id
      oid
      client_id
      employee_id
      company
      invoice_number
      quarterly
      status
      payment_type
      payment_method
      total_payment_fee
      tax
      tax_type
      tax_period
      total
      admin_fee
      tax_admin_fee
      total_down_payment
      total_discount
      total_profit
      total_sales
      total_base_price
      total_budget
      sub_total
      refund
      total_loan
      grand_total
      printed_at
      approved
      approved_at
      confirmed_at
      created_at
      updated_at
      is_custom
      total_custom_sales
      order_products {
        id
        name
        base_price
        budget
        description
        selling_price
        delivery_fee
        discount
        previousStatus
        profit
        profit_percentage
        qty
        sub_total
        status
        markup_price
        markuped
        type {
          id
          name
        }
        unit {
          id
          name
        }
      }
      employee {
        id
        name
      }
      client {
        id
        name
        regency
        address
      }
      travel_document {
        id
        order_id
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
        updated_at
      }
      order_payments {
        id
        pid
        order_id
        payment_order
        payment_fee
        payment_reference
        mutation
        difference
        payment_at
        created_at
        updated_at
      }
      loans {
        id
        amount
        loan_at
      }
      down_payments {
        id
        amount
        down_payment_at
      }
    }
  }
`;

export const CREATE_OR_UPDATE_INSTALLMENT = gql`
  mutation createOrUpdateInstallment($oid: String!, $data: InInstallment!) {
    createOrUpdateInstallment(oid: $oid, data: $data) {
      id
      oid
      client_id
      employee_id
      status
      quarterly
      total_base_price
      total_payment_fee
      payment_type
      payment_method
      total_discount
      total_sales
      tax_type
      invoice_number
      tax
      total_loan
      total_down_payment
      sub_total
      grand_total
      total_profit
      total_budget
      company
      created_at
      updated_at
      printed_at
      approved
      approved_at
      confirmed_at
      is_custom
      total_custom_sales
      order_products {
        id
        name
        base_price
        budget
        description
        selling_price
        delivery_fee
        discount
        previousStatus
        profit
        profit_percentage
        qty
        sub_total
        status
        markup_price
        markuped
        type {
          id
          name
        }
        unit {
          id
          name
        }
      }
      employee {
        id
        name
      }
      client {
        id
        name
        regency
      }
      travel_document {
        id
        order_id
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
        updated_at
      }
      order_payments {
        id
        pid
        order_id
        payment_order
        payment_fee
        payment_reference
        mutation
        difference
        payment_at
        created_at
        updated_at
      }
      loans {
        id
        amount
        loan_at
      }
      down_payments {
        id
        amount
        down_payment_at
      }
    }
  }
`;

export const ORDER_BY_EMPLOYEE = gql`
  query findOrderByEmployeeId($employee_id: ID!, $quarterly: Int) {
    findOrderByEmployeeId(employee_id: $employee_id, quarterly: $quarterly) {
      id
      oid
      client_id
      employee_id
      company
      invoice_number
      quarterly
      status
      payment_type
      payment_method
      total_payment_fee
      tax
      tax_type
      tax_period
      total
      admin_fee
      tax_admin_fee
      total_down_payment
      total_discount
      total_profit
      total_sales
      total_base_price
      total_budget
      sub_total
      refund
      total_loan
      grand_total
      printed_at
      approved
      approved_at
      confirmed_at
      created_at
      updated_at
      is_custom
      total_custom_sales
      order_products {
        id
        name
        base_price
        budget
        description
        selling_price
        delivery_fee
        discount
        previousStatus
        profit
        profit_percentage
        qty
        sub_total
        status
        markup_price
        markuped
        type {
          id
          name
        }
        unit {
          id
          name
        }
      }
      employee {
        id
        name
      }
      client {
        id
        name
        regency
        address
      }
      order_payments {
        id
        pid
        order_id
        payment_order
        payment_fee
        payment_reference
        mutation
        difference
        payment_at
        created_at
        updated_at
      }
    }
  }
`;

export const LAST_ORDER_BY_CLIENT = gql`
  query findOrderByClientId($client_id: ID!) {
    findOrderByClientId(client_id: $client_id) {
      id
      oid
      client_id
      employee_id
      company
      invoice_number
      quarterly
      status
      payment_type
      payment_method
      total_payment_fee
      tax
      tax_type
      tax_period
      total
      admin_fee
      tax_admin_fee
      total_down_payment
      total_discount
      total_profit
      total_sales
      total_base_price
      total_budget
      sub_total
      refund
      total_loan
      grand_total
      printed_at
      approved
      approved_at
      confirmed_at
      created_at
      updated_at
      is_custom
      total_custom_sales
      client {
        id
        name
        regency
        address
        __typename
      }
    }
  }
`;

export const ADD_ADDITIONAL_ITEMS = gql`
  mutation addAdditionalItems($oid: String!, $data: [InOrderProduct!]!) {
    addAdditionalItems(oid: $oid, data: $data) {
      id
      oid
      client_id
      employee_id
      company
      invoice_number
      quarterly
      status
      payment_type
      payment_method
      total_payment_fee
      tax
      tax_type
      tax_period
      total
      admin_fee
      tax_admin_fee
      total_down_payment
      total_discount
      total_profit
      total_sales
      total_base_price
      total_budget
      sub_total
      refund
      total_loan
      grand_total
      printed_at
      approved
      approved_at
      confirmed_at
      created_at
      updated_at
      is_custom
      total_custom_sales
      order_products {
        id
        name
        base_price
        budget
        description
        selling_price
        delivery_fee
        discount
        previousStatus
        profit
        profit_percentage
        qty
        sub_total
        status
        markup_price
        markuped
        type {
          id
          name
        }
        unit {
          id
          name
        }
      }
      employee {
        id
        name
      }
      client {
        id
        name
        regency
        address
      }
      travel_document {
        id
        order_id
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
        updated_at
      }
      order_payments {
        id
        pid
        order_id
        payment_order
        payment_fee
        payment_reference
        mutation
        difference
        payment_at
        created_at
        updated_at
      }
      loans {
        id
        amount
        loan_at
      }
      down_payments {
        id
        amount
        down_payment_at
      }
    }
  }
`;

export const ORDER_BY_CLIENT_ID = gql`
  query findOrderByClientId($client_id: ID!, $quarterly: Int, $name: String) {
    findOrderByClientId(
      client_id: $client_id
      quarterly: $quarterly
      name: $name
    ) {
      id
      oid
      client_id
      employee_id
      company
      invoice_number
      quarterly
      status
      payment_type
      payment_method
      total_payment_fee
      tax
      tax_type
      tax_period
      total
      admin_fee
      tax_admin_fee
      total_down_payment
      total_discount
      total_profit
      total_sales
      total_base_price
      total_budget
      sub_total
      refund
      total_loan
      grand_total
      printed_at
      approved
      approved_at
      confirmed_at
      created_at
      updated_at
      is_custom
      total_custom_sales
      order_products {
        id
        name
        base_price
        budget
        description
        selling_price
        delivery_fee
        discount
        previousStatus
        profit
        profit_percentage
        qty
        sub_total
        status
        markup_price
        markuped
        type {
          id
          name
        }
        unit {
          id
          name
        }
      }
      employee {
        id
        name
      }
    }
  }
`;

export const ADJUST_NOMINAL_ORDER = gql`
  mutation adjustNominalOrder($oid: String!) {
    adjustNominalOrder(oid: $oid) {
      id
      oid
      client_id
      employee_id
      status
      quarterly
      total_payment_fee
      total_base_price
      payment_type
      tax_type
      invoice_number
      tax
      total_loan
      total_down_payment
      sub_total
      grand_total
      total_discount
      total_sales
      total_profit
      total_budget
      company
      created_at
      updated_at
      printed_at
      approved
      approved_at
      confirmed_at
      is_custom
      total_custom_sales
      order_products {
        id
        name
        base_price
        budget
        description
        selling_price
        delivery_fee
        discount
        previousStatus
        profit
        profit_percentage
        qty
        sub_total
        status
        markup_price
        markuped
        type {
          id
          name
        }
        unit {
          id
          name
        }
      }
      employee {
        id
        name
      }
      client {
        id
        name
        regency
      }
      travel_document {
        id
        order_id
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
        updated_at
      }
      order_payments {
        id
        pid
        order_id
        payment_order
        payment_fee
        payment_reference
        mutation
        difference
        payment_at
        created_at
        updated_at
      }
      loans {
        id
        amount
        loan_at
      }
      down_payments {
        id
        amount
        down_payment_at
      }
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation deleteOrder($id: ID!) {
    deleteOrder(id: $id)
  }
`;

export const CLAIM_ORDER = gql`
  query findClaimOrderByYear($year: Int!, $employee_id: String!) {
    findClaimOrderByYear(year: $year, employee_id: $employee_id) {
      id
      total_profit
      estimate_profit
      claimed
      invoice_number
    }
  }
`;

export const CREATE_CLAIM_ORDER = gql`
  mutation createClaimOrder($data: InClaimOrder!) {
    createClaimOrder(data: $data) {
      id
      total_claim
    }
  }
`;

export const GET_CLAIMS_BY_EMPLOYEE = gql`
  query getClaimsByEmployee($employee_id: String!, $year: Int) {
    getClaimsByEmployee(employee_id: $employee_id, year: $year) {
      data {
        id
        total_claim
        created_at
      }
      total
    }
  }
`;
