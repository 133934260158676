import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  BarChart,
  Bar,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridFooter,
  GridFooterContainer,
  GridToolbarContainer,
} from '@mui/x-data-grid';

import {
  GET_EMPLOYEE_BY_EID,
  GET_EMPLOYEE_TARGET,
  GET_TOTAL_CUSTOMER,
} from '../../graphql/Employee';
import {
  CLAIM_ORDER,
  CREATE_CLAIM_ORDER,
  GET_CLAIMS_BY_EMPLOYEE,
} from '../../graphql/Order';
import GlobalContext from '../../utils/GlobalContext';
import dayjs from 'dayjs';
import { generateArrayOfYears, checkLevel, nextLevel } from '../../utils';

const EmployeeDetail = (props) => {
  const { eid } = props?.match?.params;
  const { setGlobalLoading, setSnack } = React.useContext(GlobalContext);
  const [employee, setEmployee] = React.useState({});
  const [dashboard, setDashboard] = React.useState({});
  const [percentage, setPercentage] = React.useState(0);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [companies, setCompanies] = React.useState([]);
  const [currentLevel, setCurrentLevel] = React.useState('');
  const [target, setTarget] = React.useState({ level: '-', estimate: 0 });
  const [achievement, setAchievement] = React.useState(0);
  const [claims, setClaims] = React.useState([]);
  const [availableBonus, setAvailableBonus] = React.useState(0);
  const [claimBonus, setClaimBonus] = React.useState(0);
  const [selectedClaims, setSelectedClaims] = React.useState([]);
  const [totalClaim, setTotalClaim] = React.useState({});
  const years = generateArrayOfYears();

  const columns = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 90,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'name',
      headerName: 'Customer',
      flex: 1,
    },
    {
      field: 'total',
      headerName: 'Jumlah',
      flex: 1,
    },
  ];

  const columnsClaim = [
    {
      field: 'invoice_number',
      headerName: 'Nomor Tagihan',
      flex: 1,
    },
    {
      field: 'total_profit',
      headerName: 'Laba',
      flex: 1,
      valueGetter: (params) =>
        `Rp. ${params?.row?.total_profit.toLocaleString('id-ID')}`,
    },
    {
      field: 'estimate_profit',
      headerName: 'Estimasi 5%',
      flex: 1,
      valueGetter: (params) =>
        `Rp. ${params?.row?.estimate_profit.toLocaleString('id-ID')}`,
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 100,
    },
  ];

  const columnsClaimed = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 90,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'created_at',
      headerName: 'Tanggal Klaim',
      flex: 1,
    },
    {
      field: 'total_claim',
      headerName: 'Total Klaim',
      flex: 1,
      valueGetter: (params) =>
        `Rp. ${params?.row?.total_claim?.toLocaleString('id-ID')}`,
    },
  ];

  const { loading, data } = useQuery(GET_EMPLOYEE_BY_EID, {
    variables: { eid },
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });
  const { loading: loadingDashboard, data: dataDashboard } = useQuery(
    GET_EMPLOYEE_TARGET,
    {
      variables: { eid, year },
      fetchPolicy: 'network-only',
      onError: (e) => console.log('error getting selor:', e),
    }
  );
  const { loading: loadingCompanies, data: dataCompanies } = useQuery(
    GET_TOTAL_CUSTOMER,
    {
      variables: { eid, year },
      fetchPolicy: 'network-only',
      onError: (e) => console.log('error getting selor:', e),
    }
  );

  const {
    loading: loadingClaim,
    data: dataClaim,
    refetch,
  } = useQuery(CLAIM_ORDER, {
    variables: { employee_id: eid, year },
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  const {
    loading: loadingTotalClaim,
    data: dataTotalClaim,
    refetch: refetch_total_claim,
  } = useQuery(GET_CLAIMS_BY_EMPLOYEE, {
    variables: { employee_id: eid, year },
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (loading) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (data) {
      setEmployee(data.getEmployeeByEid);
      setGlobalLoading(false);
    }
  }, [data, setGlobalLoading]);

  React.useEffect(() => {
    if (dataClaim) {
      const claims = dataClaim?.findClaimOrderByYear;
      setClaims(claims);
      const ab = claims
        ?.filter((c) => !c.claimed)
        ?.reduce(
          (n, { estimate_profit }) => Number(n) + Number(estimate_profit),
          0
        );
      setAvailableBonus(ab);
      setGlobalLoading(false);
    }
  }, [dataClaim, setGlobalLoading]);

  React.useEffect(() => {
    if (dataCompanies) {
      setCompanies(dataCompanies.getTotalCustomer);
      setGlobalLoading(false);
    }
  }, [dataCompanies, setGlobalLoading]);

  React.useEffect(() => {
    if (dataTotalClaim) {
      setTotalClaim(dataTotalClaim.getClaimsByEmployee);
      setGlobalLoading(false);
    }
  }, [dataTotalClaim, setGlobalLoading]);

  React.useEffect(() => {
    if (dataDashboard) {
      setDashboard(dataDashboard.getEmployeeTarget);
      const _percentage = Math.round(
        (dataDashboard?.getEmployeeTarget?.total_profit /
          dataDashboard?.getEmployeeTarget?.company_profit) *
          100
      );

      if (_percentage) setPercentage(_percentage);
      setGlobalLoading(false);

      if (year === new Date().getFullYear()) {
        const profit = dataDashboard?.getEmployeeTarget?.total_profit;
        const level = checkLevel(profit);
        setCurrentLevel(level);
        const lvl = nextLevel(level, profit);
        setTarget({ level: lvl.level, estimate: lvl.estimate });
        setAchievement(profit);
      }
    }
  }, [dataDashboard, year]);

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Box style={{ marginLeft: 10 }}>
          <Box>
            <Typography variant='subtitle2'>
              Bonus Tersedia Rp. {availableBonus?.toLocaleString('id-ID')}
            </Typography>
          </Box>
          <Box>
            <Typography variant='subtitle2'>
              Bonus Terklaim Rp. {claimBonus?.toLocaleString('id-ID')}
            </Typography>
          </Box>
        </Box>
        <GridFooter
          sx={{
            border: 'none',
          }}
        />
      </GridFooterContainer>
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: 'flex-end',
          paddingRight: '15px',
          paddingTop: '10px',
        }}
      >
        <Box>
          <Button
            variant='contained'
            size='small'
            disabled={!selectedClaims?.length}
            onClick={handleSubmit}
          >
            Klaim
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  };

  const [createClaimOrder] = useMutation(CREATE_CLAIM_ORDER, {
    onCompleted: (d) => {
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data klaim berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      refetch();
      refetch_total_claim();
      return d?.createClaimOrder;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Data klaim gagal disimpan ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const handleSubmit = async () => {
    const total_claim =
      selectedClaims?.reduce(
        (n, { estimate_profit }) => Number(n) + Number(estimate_profit),
        0
      ) ?? 0;
    const order_ids = selectedClaims?.map((x) => x.id) ?? [];
    const data = {
      employee_id: employee?.id,
      total_claim,
      order_ids,
    };

    return createClaimOrder({ variables: { data } });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Box sx={{ mb: 3, mr: 3 }}>
          <img
            src={
              employee?.image ??
              'https://res.cloudinary.com/dkv1a9nmy/image/upload/v1741870576/employees/blank-profile-picture-973460_960_720_gtz20l.webp'
            }
            width='200'
            height='200'
            alt=''
          />
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Nama Marketing
          </Typography>
          <Typography variant='body2'>{employee?.name}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Tempat / Tanggal Lahir
          </Typography>
          <Typography variant='body2'>
            {employee?.place_of_birth},{' '}
            {dayjs(employee?.date_of_birth).format('DD/MM/YYYY')}
          </Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            No Telp.
          </Typography>
          <Typography variant='body2'>{employee?.phone}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            ID Perusahaan
          </Typography>
          <Typography variant='body2'>{employee?.company_id ?? '-'}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Status
          </Typography>
          <Typography>
            <Chip
              label={employee?.status}
              variant='filled'
              color={'info'}
              size='small'
            />
          </Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Level
          </Typography>
          <Typography>
            <Chip
              label={checkLevel(dashboard.total_profit).toLocaleUpperCase()}
              variant='filled'
              color={'success'}
              size='small'
            />
          </Typography>
        </Box>
        <Box sx={{ width: `calc(100vw - 10)`, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel id='year'>Tahun</InputLabel>
            <Select
              labelId='year'
              id='year'
              name='year'
              label='Tahun'
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {years.map((x, i) => (
                <MenuItem key={i} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Typography variant='h6' style={{ marginTop: 10 }}>
        Pencapaian & Target
      </Typography>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Pencapaian
          </Typography>
          <Typography variant='body2'>
            Rp. {achievement?.toLocaleString('id-ID') ?? 0}
          </Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Target
          </Typography>
          <Typography variant='body2'>{target?.level}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Estimasi Target Nominal
          </Typography>
          <Typography variant='body2'>
            Rp. {target?.estimate?.toLocaleString('id-ID')}
          </Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Target Hingga
          </Typography>
          <Typography variant='body2'>
            Desember {new Date().getFullYear()}
          </Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Bonus Klaim Tahun {year}
          </Typography>
          <Typography variant='body2'>
            Rp. {totalClaim?.total?.toLocaleString('id-ID') ?? 0}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ResponsiveContainer id='2' width='95%' height={300}>
            <BarChart
              width={600}
              height={400}
              data={[dashboard]}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              {/* <XAxis
                tickFormatter={(value) => `Cawu ${value}`}
                dataKey='quarterly'
              /> */}
              <YAxis
                tickFormatter={(value) =>
                  new Intl.NumberFormat('id', {
                    notation: 'compact',
                    compactDisplay: 'long',
                  }).format(value)
                }
              />
              <Tooltip
                labelFormatter={(value) => `Pencapaian Target`}
                formatter={(value, name, props) => {
                  if (name === 'total_profit') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Total Laba',
                      props,
                    ];
                  }

                  if (name === 'pending_profit') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Laba Tertahan',
                      props,
                    ];
                  }

                  if (name === 'personal_debt') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Hutang Pribadi',
                      props,
                    ];
                  }

                  if (name === 'selling_profit') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Laba Penjualan',
                      props,
                    ];
                  }

                  if (name === 'invoice_profit') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Laba Sisipan',
                      props,
                    ];
                  }
                }}
              />
              <Bar dataKey='total_profit' fill='#8884d8' />
              <Bar dataKey='pending_profit' fill='#82ca9d' />
              <Bar dataKey='personal_debt' fill='#84d8c7' />
              <Bar dataKey='selling_profit' fill='#d8d884' />
              <Bar dataKey='invoice_profit' fill='#CBA0AE' />
              <Legend
                payload={[
                  {
                    id: '1',
                    type: 'circle',
                    value: 'Total Laba',
                    color: '#8884d8',
                  },
                  {
                    id: '2',
                    type: 'circle',
                    value: 'Laba Tertahan',
                    color: '#82ca9d',
                  },
                  {
                    id: '3',
                    type: 'circle',
                    value: 'Hutang Pribadi',
                    color: '#84d8c7',
                  },
                  {
                    id: '4',
                    type: 'circle',
                    value: 'Laba Penjualan',
                    color: '#d8d884',
                  },
                  {
                    id: '5',
                    type: 'circle',
                    value: 'Laba Sisipan',
                    color: '#CBA0AE',
                  },
                ]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant='caption'>
            * laba yang dihasilkan {percentage}% dari laba perusahaan
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={companies}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={totalClaim?.data ?? []}
              columns={columnsClaimed}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={claims}
              columns={columnsClaim}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              isRowSelectable={(params) => !params.row.claimed}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = claims.filter((row) =>
                  selectedIDs.has(row.id.toString())
                );
                const bonus = selectedRowData?.reduce(
                  (n, { estimate_profit }) =>
                    Number(n) + Number(estimate_profit),
                  0
                );

                setClaimBonus(bonus);
                setSelectedClaims(selectedRowData);
              }}
              components={{
                Footer: CustomFooter,
                Toolbar: CustomToolbar,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EmployeeDetail;
