import { gql } from '@apollo/client';

export const CLIENTS = gql`
  query clients {
    clients {
      id
      cid
      employee_id
      name
      address
      anniversary
      regency
      pic_eksternal
      phone
      created_at
      updated_at
      type
      employee {
        id
        name
      }
    }
  }
`;

export const SEARCH_CLIENT = gql`
  query searchClients($name: String, $regency: String, $employee_id: ID) {
    searchClients(name: $name, regency: $regency, employee_id: $employee_id) {
      id
      cid
      employee_id
      name
      address
      anniversary
      regency
      pic_eksternal
      phone
      created_at
      updated_at
      type
      employee {
        id
        name
      }
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation createClient($data: InClient!) {
    createClient(data: $data) {
      id
      cid
      employee_id
      name
      address
      anniversary
      regency
      pic_eksternal
      phone
      created_at
      updated_at
      type
      employee {
        id
        name
      }
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient($cid: String!, $data: InClient!) {
    updateClient(cid: $cid, data: $data) {
      id
      cid
      employee_id
      name
      address
      anniversary
      regency
      pic_eksternal
      phone
      created_at
      updated_at
      type
      employee {
        id
        name
      }
    }
  }
`;

export const CLIENT_BY_CID = gql`
  query findClientByCid($cid: String!) {
    findClientByCid(cid: $cid) {
      id
      cid
      employee_id
      name
      address
      anniversary
      regency
      pic_eksternal
      phone
      created_at
      updated_at
      type
      employee {
        id
        name
      }
    }
  }
`;
