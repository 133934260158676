import { gql } from '@apollo/client';

export const GET_DASHBOARD = gql`
  query getTotalDashboard($year: Int!, $employee_id: Int) {
    getTotalDashboard(year: $year, employee_id: $employee_id) {
      total_base_price
      total_sales
      total_profit
      total_discount
      quarterly
      total_platform_fee_bill
      total_platform_fee_invoice
      total_delivery_fee
    }
  }
`;

export const GET_SUMMARY = gql`
  query getSummary($year: Int!, $employee_id: Int) {
    getSummary(year: $year, employee_id: $employee_id) {
      total_order
      new_order
      process_order
      done_order
    }
  }
`;

export const GET_DASHBOARD_ESTIMATE = gql`
  query getDashboardEstimate($year: Int!, $employee_id: Int) {
    getDashboardEstimate(year: $year, employee_id: $employee_id) {
      estimate_total_sales
      estimate_profit
      quarterly
    }
  }
`;

export const EXPORT_REPORT = gql`
  query exportReport(
    $year: Int!
    $quarterly: Int!
    $employee_id: Int!
    $type: String!
  ) {
    exportReport(
      year: $year
      quarterly: $quarterly
      employee_id: $employee_id
      type: $type
    )
  }
`;

export const GET_DASHBOARD_PHOTOCOPIES = gql`
  query getDashboardPhotocopies {
    getDashboardPhotocopies {
      due_date {
        total
        data {
          id
          status
        }
      }
    }
  }
`;
