/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import {
  CLIENTS,
  SEARCH_CLIENT,
  CREATE_CLIENT,
  UPDATE_CLIENT,
} from '../../graphql/Client';
import { EMPLOYEES } from '../../graphql/Employee';
import Box from '@mui/material/Box';
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import InfoIcon from '@mui/icons-material/Info';
import Badge from '@mui/material/Badge';
import MyBreadcrumbs from '../MyBreadcrumbs';
import GlobalContext from '../../utils/GlobalContext';
import DrawerClient from './DrawerClient';

const ClientForm = React.lazy(() => import('./ClientForm'));

const ClientPage = (props) => {
  const { role } = props;
  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);
  const [clients, setClients] = React.useState([]);
  const [baseClients, setBaseClients] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);
  const [searched, setSearched] = React.useState('');
  const [isSearch, setIsSearch] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [data, setData] = React.useState({});
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [regency, setRegency] = React.useState('ALL');
  const [marketing, setMarketing] = React.useState('');
  const [isFilter, setIsFilter] = React.useState(false);

  const columns = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 90,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'name',
      headerName: 'Nama Pelanggan',
      width: 250,
    },
    {
      field: 'address',
      headerName: 'Alamat',
      width: 250,
    },
    {
      field: 'regency',
      headerName: 'Wilayah',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Nomor Telepon',
      flex: 1,
    },
    {
      field: 'pic_internal',
      headerName: 'PIC Internal',
      flex: 1,
      valueGetter: (params) => params.row?.employee?.name,
    },
    {
      field: 'type',
      headerName: 'Tipe',
      flex: 1,
    },
  ];

  if (role !== 'MONITORING') {
    columns.push({
      field: 'cid',
      headerName: 'Aksi',
      width: 90,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <Tooltip title='Ubah' placement='right'>
              <IconButton
                aria-label='edit'
                size='small'
                onClick={() => {
                  setEdit(true);
                  setData(params?.row);
                  setOpen(true);
                }}
              >
                <EditIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Detail' placement='top'>
              <IconButton
                aria-label='detail'
                size='small'
                component={Link}
                to={`/admin/client/${params.row.cid}`}
              >
                <InfoIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      },
    });
  }

  const requestSearch = (value) => {
    setSearched(value);
  };

  const clearSearch = () => {
    setSearched('');
    setIsSearch(false);
    setClients([...baseClients]);
  };

  const doSearch = async () => {
    setIsSearch(true);
    const data = await getClients({
      variables: { name: searched },
    });

    setClients([...data.data?.searchClients]);
  };

  /* FETCH DATA CLIENTS */
  const { loading: loadClients, data: dataClients } = useQuery(CLIENTS, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  /* FETCH DATA SEARCH CLIENTS */
  const [getClients] = useLazyQuery(SEARCH_CLIENT, {
    fetchPolicy: 'network-only',
  });

  /* FETCH DATA EMPLOYEES */
  const { data: dataEmployees } = useQuery(EMPLOYEES, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (dataEmployees) {
      setEmployees(dataEmployees.employees);
    }
  }, [dataEmployees]);

  /* MUTATE DATA */
  const [insertClient] = useMutation(CREATE_CLIENT, {
    onCompleted: (d) => {
      const data = clients;
      setClients([...data, d.createClient]);
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Pelanggan berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
    },
    onError: (e) => {
      console.log('error verify user: ', e);
      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message: 'Data Pelanggan gagal disimpan',
        opened: true,
      });
    },
  });

  const [updateClient] = useMutation(UPDATE_CLIENT, {
    onCompleted: (d) => {
      const data = clients;
      const updatedData = data.map((x) => {
        if (x.id === d.updateClient.id) {
          return { ...d.updateClient };
        }

        return x;
      });

      setClients([...updatedData]);
      setSnack({
        variant: 'success',
        message: 'Data Pelanggan berhasil diubah',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
      setEdit(false);
      setData({});
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = 'Data Pelanggan gagal diubah';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  /* BUTTON DIALOG */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /* INSERT DATA */
  const handleSubmit = async (values) => {
    setGlobalLoading(true);
    if (edit) {
      updateClient({
        variables: {
          cid: data.cid,
          data: values,
        },
      });
    } else {
      insertClient({
        variables: { data: values },
      });
    }
  };

  React.useEffect(() => {
    if (loadClients) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataClients) {
      setBaseClients(dataClients.clients);
      setClients(dataClients.clients);
      setGlobalLoading(false);
    }
  }, [dataClients]);

  const doFilter = async () => {
    setIsFilter(true);
    setGlobalLoading(true);
    setOpenDrawer(false);
    setIsSearch(true);
    const data = await getClients({
      variables: { regency, employee_id: marketing },
    });

    setClients([...data.data?.searchClients]);
    setGlobalLoading(false);
  };

  const resetFilter = () => {
    setIsFilter(false);
    setOpenDrawer(false);
    setClients([...baseClients]);
    setRegency('ALL');
    setMarketing('');
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs data={['Master', 'Pelanggan']} />
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <FormControl size='small' margin='dense'>
            <OutlinedInput
              name='search'
              margin='dense'
              id='search'
              placeholder='Cari'
              onChange={(e) => requestSearch(e.target.value)}
              value={searched}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doSearch();
                }
              }}
              endAdornment={
                <InputAdornment position='end'>
                  {isSearch && (
                    <IconButton onClick={() => clearSearch()}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  {!isSearch && (
                    <IconButton onClick={() => doSearch()}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        {role !== 'MONITORING' && (
          <Box
            sx={{
              p: 0.5,
              m: 0.5,
            }}
          >
            <Tooltip title='Tambah' placement='top'>
              <IconButton
                aria-label='add'
                size='small'
                onClick={() => handleClickOpen()}
                style={{
                  backgroundColor: '#1976d2',
                  color: 'white',
                  marginRight: 10,
                }}
              >
                <AddIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Filter' placement='top'>
              <IconButton
                aria-label='add'
                size='small'
                onClick={() => setOpenDrawer(true)}
                style={{ backgroundColor: '#1976d2', color: 'white' }}
              >
                <Badge invisible={!isFilter} color='error' variant='dot'>
                  <FilterListIcon fontSize='inherit' />
                </Badge>
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={clients}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Paper>

      <React.Suspense fallback={<div></div>}>
        <ClientForm
          open={open}
          setOpen={setOpen}
          handleSubmit={handleSubmit}
          employees={employees}
          setEdit={setEdit}
          edit={edit}
          setData={setData}
          data={data}
        />
      </React.Suspense>

      <React.Suspense fallback={<div></div>}>
        <DrawerClient
          open={openDrawer}
          setOpen={setOpenDrawer}
          marketing={marketing}
          setMarketing={setMarketing}
          regency={regency}
          setRegency={setRegency}
          doFilter={doFilter}
          resetFilter={resetFilter}
          employees={employees}
        />
      </React.Suspense>
    </React.Fragment>
  );
};

export default ClientPage;
