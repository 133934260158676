/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { DEBTS, CREATE_DEBT } from '../../graphql/Debt';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MyBreadcrumbs from '../MyBreadcrumbs';
import GlobalContext from '../../utils/GlobalContext';

const DebtForm = React.lazy(() => import('./DebtForm'));

const DebtPage = (props) => {
  const { role } = props;
  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);
  const [debts, setDebts] = React.useState([]);
  const [baseDebts, setBaseDebts] = React.useState([]);
  const [searched, setSearched] = React.useState('');
  const [isSearch, setIsSearch] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [data, setData] = React.useState({});

  const columns = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 90,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'name',
      headerName: 'Nama Pegawai',
      flex: 1,
      renderCell: (params) => `${params?.row?.employee?.name}`,
    },
    {
      field: 'transaction_date',
      headerName: 'Tanggal Pinjaman',
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Jumlah Pinjaman',
      flex: 1,
      renderCell: (params) => {
        return `Rp. ${params?.row?.amount?.toLocaleString('id-ID')}`;
      },
    },
    {
      field: 'description',
      headerName: 'Keterangan',
      flex: 1,
    },
  ];

  const requestSearch = (value) => {
    setSearched(value);
  };

  const clearSearch = () => {
    setSearched('');
    setIsSearch(false);
    setDebts([...baseDebts]);
  };

  const doSearch = async () => {
    setIsSearch(true);
    // const data = await getPhotocopies({
    //   variables: { name: searched },
    // });

    // setDebts([...data.data?.searchPhotocopy]);
  };

  /* FETCH DATA PHOTOCOPIES */
  const { loading: loadDebts, data: dataDebts } = useQuery(DEBTS, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  /* MUTATE DATA */
  const [insertDebt] = useMutation(CREATE_DEBT, {
    onCompleted: (d) => {
      const data = debts;
      setDebts([...data, d.createDebt]);
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Pinjaman berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
    },
    onError: (e) => {
      console.log('error verify user: ', e);
      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message: 'Data Pinjaman gagal disimpan',
        opened: true,
      });
    },
  });

  /* BUTTON DIALOG */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /* INSERT DATA */
  const handleSubmit = async (values) => {
    setGlobalLoading(true);
    debugger;
    insertDebt({ variables: { data: values } });
  };

  React.useEffect(() => {
    if (loadDebts) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataDebts) {
      setBaseDebts(dataDebts.debts);
      setDebts(dataDebts.debts);
      setGlobalLoading(false);
    }
  }, [dataDebts]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs data={['Master', 'Pinjaman']} />
        </Box>

        {role !== 'MONITORING' && (
          <Box
            sx={{
              p: 0.5,
              m: 0.5,
            }}
          >
            <Tooltip title='Tambah' placement='top'>
              <IconButton
                aria-label='add'
                size='small'
                onClick={() => handleClickOpen()}
                style={{
                  backgroundColor: '#1976d2',
                  color: 'white',
                  marginRight: 10,
                }}
              >
                <AddIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={debts}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Paper>

      <React.Suspense fallback={<div></div>}>
        <DebtForm
          open={open}
          setOpen={setOpen}
          handleSubmit={handleSubmit}
          setEdit={setEdit}
          edit={edit}
          setData={setData}
          data={data}
        />
      </React.Suspense>
    </React.Fragment>
  );
};

export default DebtPage;
