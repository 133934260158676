import { gql } from '@apollo/client';

export const EMPLOYEES = gql`
  query employees {
    employees {
      id
      eid
      name
      phone
      email
      role
      created_at
      updated_at
      date_of_birth
      place_of_birth
      company_id
      status
      expired_date
    }
  }
`;

export const SEARCH_EMPLOYEE = gql`
  query searchEmployees($name: String) {
    searchEmployees(name: $name) {
      id
      eid
      name
      phone
      email
      role
      created_at
      updated_at
      date_of_birth
      place_of_birth
      company_id
      status
      expired_date
    }
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee($data: InEmployee!) {
    createEmployee(data: $data) {
      id
      eid
      name
      phone
      email
      role
      created_at
      updated_at
      date_of_birth
      place_of_birth
      company_id
      status
      expired_date
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($eid: String!, $data: InEmployee!) {
    updateEmployee(eid: $eid, data: $data) {
      id
      eid
      name
      phone
      email
      role
      created_at
      updated_at
      date_of_birth
      place_of_birth
      company_id
      status
      expired_date
    }
  }
`;

export const GET_EMPLOYEE_BY_EID = gql`
  query getEmployeeByEid($eid: String) {
    getEmployeeByEid(eid: $eid) {
      id
      name
      status
      place_of_birth
      phone
      date_of_birth
      email
      role
      expired_date
      image
    }
  }
`;

export const GET_EMPLOYEE_TARGET = gql`
  query getEmployeeTarget($eid: String!, $year: Int!) {
    getEmployeeTarget(eid: $eid, year: $year) {
      invoice_profit
      pending_profit
      personal_debt
      selling_profit
      total_profit
      company_profit
    }
  }
`;

export const GET_TOTAL_CUSTOMER = gql`
  query getTotalCustomer($eid: String!, $year: Int!) {
    getTotalCustomer(eid: $eid, year: $year) {
      id
      name
      total
    }
  }
`;
